<template>
  <div class="call">
    <el-tabs v-model="active">
      <el-tab-pane label="待检列表" name="1">
        <div class="nav">
          <div class="calleeNumber">
            客户编号：
            <el-input
              v-model="condition.customerCode"
              placeholder="多个以逗号分割"
              clearable
            ></el-input>
          </div>
          <div class="callerNumber">
            坐席编号：
            <el-input
              v-model="condition.clientNumber"
              placeholder="请输入坐席编号"
              clearable
            ></el-input>
          </div>
          <div class="callerNumber" style="width: 530px">
            完成时间：
            <el-date-picker
              class="startTime"
              v-model="condition.callStartAtStart"
              type="datetime"
              placeholder="起始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
            ></el-date-picker>
            -
            <el-date-picker
              class="endTime"
              v-model="condition.callStartAtEnd"
              type="datetime"
              placeholder="终止时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </div>
          <div class="callerNumber">
            批次编号：
            <el-input
              v-model="condition.batchNumber"
              placeholder="请输入批次编号"
              clearable
            ></el-input>
          </div>
          <div class="callerNumber">
            选择项目：
            <el-select
              v-model="condition.projectId"
              filterable
              remote
              :loading="loading"
              :remote-method="remoteMethod"
              @focus="projectIdRemoteFocus"
              placeholder="请选择项目名称"
              @change="projectIdChange"
              clearable
            >
              <el-option
                v-for="item in projectNameList"
                :key="item.id"
                :value="item.id"
                :label="item.projectName"
              ></el-option>
            </el-select>
          </div>
          <div class="callerNumber" style="width: 530px">
            成单时间：
            <el-date-picker
              class="startTime"
              v-model="condition.flagModifiedAtStart"
              type="datetime"
              placeholder="起始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
            ></el-date-picker>
            -
            <el-date-picker
              class="endTime"
              v-model="condition.flagModifiedAtEnd"
              type="datetime"
              placeholder="终止时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </div>
          <div class="callerNumber" v-if="roleMenu == 'SUPER_MANAGER'">
            用户名称：
            <el-select
              v-model="condition.userName"
              filterable
              remote
              :loading="loadingName"
              :remote-method="NameRemoteMethod"
              placeholder="请选择用户名称"
              @change="loginNameChange"
              @focus="nameRemoteFocus"
              clearable
            >
              <el-option
                v-for="item in userNameList"
                :key="item.id"
                :value="item.loginName"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="callerNumber">
            选择任务：
            <el-select
              v-model="condition.taskId"
              filterable
              remote
              :loading="loadingTask"
              :remote-method="taskRemoteMethod"
              @focus="taskRemoteFocus"
              placeholder="请选择任务名称"
              @change="taskIdChange"
              clearable
            >
              <el-option
                v-for="item in taskNameList"
                :key="item.id"
                :value="item.id"
                :label="item.taskName"
              ></el-option>
            </el-select>
          </div>
          <div class="callerNumber" v-if="roleMenu != 'SUPER_MANAGER'">
            选择队列：
            <el-select
              v-model="condition.teamId"
              placeholder="请选择队列名称"
              :disabled="teamSelectDisabled"
              clearable
            >
              <el-option
                v-for="item in teamNameList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
          <div class="callerNumber" style="width: 530px">
            通话时长：
            <el-input
              v-model="condition.callMillisStart"
              placeholder="请输入开始时间"
              clearable
            ></el-input>
            -
            <el-input
              v-model="condition.callMillisEnd"
              placeholder="请输入结束时间"
              clearable
            ></el-input>
          </div>
          <div class="callerNumber" v-if="roleMenu == 'SUPER_MANAGER'">
            选择队列：
            <el-select
              v-model="condition.teamId"
              placeholder="请选择队列名称"
              :disabled="teamSelectDisabled"
              clearable
            >
              <el-option
                v-for="item in teamNameList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
          <div class="callerNumber">
            微信状态：
            <el-select
              v-model="condition.wechatStatusList"
              multiple
              collapse-tags
              placeholder="请选择微信状态"
              clearable
            >
              <el-option
                v-for="item in wechatStatusList"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="callerNumber" v-if="roleMenu == 'SUPER_MANAGER'">
            质检标签：
            <el-select
              v-model="condition.recordingLabelList"
              clearable
              placeholder="请选择质检标签"
              multiple
              collapse-tags
              filterable
            >
              <el-option
                v-for="(item, index) in recordTagList"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </div>
          <div
            class="callerNumber"
            style="width: 160px"
            v-if="roleMenu == 'SUPER_MANAGER'"
          >
            <el-checkbox v-model="skipHasBoughtLesson" style="line-height: 40px"
              >过滤已买课</el-checkbox
            >
          </div>
        </div>
        <div
          :class="{ operate: roleMenu !== 'SUPER_MANAGER' }"
          style="clear: both"
        >
          <el-button type="primary" @click="inquire(1)">查询</el-button>
          <el-button
            type="success"
            @click="exportCheck"
            :disabled="downloadFormDisabled"
            >导出</el-button
          >
          <el-button type="text" @click="firstSelfFiltering()">{{
            selfFiltertext
          }}</el-button>
        </div>
        <!-- 待检列表 -->
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          border
          class="answer-class"
          v-loading="loadingTable"
        >
          <el-table-column
            prop="batchNumber"
            label="批次编号"
            width="90"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="customerCode"
            label="客户编号"
            width="130"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="clientNumber"
            label="坐席编号"
            width="130"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="clientName"
            label="坐席名称"
            width="130"
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="callSeconds"
            label="通话时长"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="customerProvince"
            label="归属地-省"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="customerCity"
            label="归属地-城市"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="callEndAt"
            label="完成时间"
            width="190"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="flagModifiedAt"
            label="成单时间"
            width="190"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="wechatStatusLabel"
            label="微信状态"
            width="190"
            align="center"
          ></el-table-column>
          <el-table-column
            prop
            :label="item.question"
            v-for="(item, key) in header"
            :key="key"
            :width="flexColumnWidth(item, item.questionType)"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                v-if="
                  questionTypeList.indexOf(item.questionType) > -1
                    ? true
                    : false
                "
              >
                <div v-for="(val, key) in scope.row.answerMap" :key="key">
                  <span
                    v-show="item.id == key"
                    v-if="
                      answerInputList.indexOf(item.questionType) > -1
                        ? true
                        : false
                    "
                    >{{ val }}</span
                  >
                  <span
                    v-show="item.id == key"
                    v-if="item.questionType == 'CHECKBOX'"
                    >{{ scope.row.option["option_" + item.id] }}</span
                  >
                </div>
              </div>
              <div v-if="item.questionType == 'RADIO'">
                <div v-for="(menu, index) in item.optionList" :key="index">
                  <span
                    v-show="scope.row.option['option_' + item.id] == menu.id"
                    >{{ menu.option }}</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="operatorRemark"
            label="微信销售备注"
            width="130"
            align="center"
            show-overflow-tooltip
            v-if="roleMenu == 'SUPER_MANAGER'"
          ></el-table-column>
          <el-table-column
            prop="operatorRecordingList"
            label="微信销售质检"
            width="130"
            align="center"
            show-overflow-tooltip
            v-if="roleMenu == 'SUPER_MANAGER'"
          >
            <template slot-scope="scope">
              <el-tag
                v-for="tag in scope.row.operatorRecordingList"
                :key="tag.id"
                size="mini"
                class="tags_td"
                >{{ tag.label }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop
            label="操作"
            :width="roleMenu === 'SUPER_MANAGER' ? '270' : '230'"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="playRecord(scope.row)"
                >质检录音</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="downloadVoice(scope.row)"
                >下载录音</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="associatedRecords(scope.row)"
                >关联记录</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="dialogueTranslate(scope.row)"
                :disabled="scope.row.playVoiceDisabled"
                >对话翻译</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @current-change="currentPage_data"
          :current-page.sync="currentPage"
          :page-size="10"
          @size-change="sizeChange"
          :page-sizes="[10, 20, 30, 40]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="total"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="我的质检" name="2">
        <div class="nav">
          <div class="calleeNumber">
            客户编号：
            <el-input
              v-model="conditionWd.customerCode"
              placeholder="多个以逗号分割"
              clearable
            ></el-input>
          </div>
          <div class="callerNumber">
            坐席编号：
            <el-input
              v-model="conditionWd.clientNumber"
              placeholder="请输入坐席编号"
              clearable
            ></el-input>
          </div>
          <div class="callerNumber" style="width: 530px">
            完成时间：
            <el-date-picker
              class="startTime"
              v-model="conditionWd.callStartAtStart"
              type="datetime"
              placeholder="起始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
            ></el-date-picker>
            -
            <el-date-picker
              class="endTime"
              v-model="conditionWd.callStartAtEnd"
              type="datetime"
              placeholder="终止时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </div>
          <div class="callerNumber">
            批次编号：
            <el-input
              v-model="conditionWd.batchNumber"
              placeholder="请输入批次编号"
              clearable
            ></el-input>
          </div>
          <div class="callerNumber">
            选择项目：
            <el-select
              v-model="conditionWd.projectId"
              filterable
              remote
              :loading="loading"
              :remote-method="remoteMethod"
              @focus="projectIdRemoteFocus"
              placeholder="请选择项目名称"
              @change="projectId2Change"
              clearable
            >
              <el-option
                v-for="item in projectNameList"
                :key="item.id"
                :value="item.id"
                :label="item.projectName"
              ></el-option>
            </el-select>
          </div>
          <div class="callerNumber" style="width: 530px">
            成单时间：
            <el-date-picker
              class="startTime"
              v-model="conditionWd.flagModifiedAtStart"
              type="datetime"
              placeholder="起始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
            ></el-date-picker>
            -
            <el-date-picker
              class="endTime"
              v-model="conditionWd.flagModifiedAtEnd"
              type="datetime"
              placeholder="终止时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </div>
          <div class="callerNumber" v-if="roleMenu == 'SUPER_MANAGER'">
            用户名称：
            <el-select
              v-model="conditionWd.userName"
              filterable
              remote
              :loading="loadingName"
              :remote-method="NameRemoteMethod"
              placeholder="请选择用户名称"
              @change="loginNameChange"
              @focus="nameRemoteFocus"
              clearable
            >
              <el-option
                v-for="item in userNameList"
                :key="item.id"
                :value="item.loginName"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="callerNumber">
            选择任务：
            <el-select
              v-model="conditionWd.taskId"
              filterable
              remote
              :loading="loadingTask"
              :remote-method="taskRemoteMethod"
              @focus="taskRemoteFocus"
              placeholder="请选择任务名称"
              @change="taskIdChange"
              clearable
            >
              <el-option
                v-for="item in taskNameList"
                :key="item.id"
                :value="item.id"
                :label="item.taskName"
              ></el-option>
            </el-select>
          </div>
          <div class="callerNumber" v-if="roleMenu != 'SUPER_MANAGER'">
            选择队列：
            <el-select
              v-model="conditionWd.teamId"
              placeholder="请选择队列名称"
              :disabled="teamSelectDisabled"
              clearable
            >
              <el-option
                v-for="item in teamNameList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
          <div class="callerNumber" style="width: 530px">
            通话时长：
            <el-input
              v-model="conditionWd.callMillisStart"
              placeholder="请输入开始时间"
              clearable
            ></el-input>
            -
            <el-input
              v-model="conditionWd.callMillisEnd"
              placeholder="请输入结束时间"
              clearable
            ></el-input>
          </div>
          <div class="callerNumber" v-if="roleMenu == 'SUPER_MANAGER'">
            选择队列：
            <el-select
              v-model="conditionWd.teamId"
              placeholder="请选择队列名称"
              :disabled="teamSelectDisabled"
              clearable
            >
              <el-option
                v-for="item in teamNameList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
          <div class="callerNumber">
            质检结果：
            <el-select
              v-model="conditionWd.checkResult"
              placeholder="请选择质检结果"
              clearable
            >
              <el-option
                v-for="item in conditionWdList"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="callerNumber" v-if="roleMenu == 'SUPER_MANAGER'">
            质检标签：
            <el-select
              v-model="conditionWd.recordingLabelList"
              clearable
              placeholder="请选择质检标签"
              multiple
              collapse-tags
              filterable
            >
              <el-option
                v-for="(item, index) in recordTagList"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div
          :class="{ operate: roleMenu !== 'SUPER_MANAGER' }"
          style="clear: both"
        >
          <el-button type="primary" @click="inquireWd(1)">查询</el-button>
          <el-button
            type="success"
            @click="exportWdCheck"
            :disabled="downloadFormDisabled"
            >导出</el-button
          >
          <el-button type="text" @click="secondSelfFiltering()">{{
            selfFiltertext2
          }}</el-button>
        </div>
        <!-- 我的质检 -->
        <el-table
          :data="wdTableData"
          stripe
          style="width: 100%"
          border
          class="answer-class"
          v-loading="loadingTable"
        >
          <el-table-column
            prop="batchNumber"
            label="批次编号"
            width="90"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="customerCode"
            label="客户编号"
            width="130"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="clientNumber"
            label="坐席编号"
            width="130"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="clientName"
            label="坐席名称"
            width="130"
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="callSeconds"
            label="通话时长"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="customerProvince"
            label="归属地-省"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="customerCity"
            label="归属地-城市"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="callEndAt"
            label="完成时间"
            width="190"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="flagModifiedAt"
            label="成单时间"
            width="190"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="checkResult"
            label="质检结果"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.checkResult === 'PASSED'"
                type="success"
                size="small"
                >质检通过</el-tag
              >
              <el-tag
                v-if="scope.row.checkResult === 'NO_CHECK'"
                type="info"
                size="small"
                >未质检</el-tag
              >
              <el-tooltip
                v-if="scope.row.checkResult === 'REJECTED'"
                :content="
                  '质检人：' +
                  scope.row.checker +
                  ' | ' +
                  '备注：' +
                  scope.row.remark
                "
                placement="top"
              >
                <el-tag type="danger" size="small">质检不通过</el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="failureReason"
            label="质检不通过原因"
            width="190"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="punishment"
            label="处罚措施"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop
            :label="item.question"
            v-for="(item, key) in header"
            :key="key"
            :width="flexColumnWidth(item, item.questionType)"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                v-if="
                  questionTypeList.indexOf(item.questionType) > -1
                    ? true
                    : false
                "
              >
                <div v-for="(val, key) in scope.row.answerMap" :key="key">
                  <span
                    v-show="item.id == key"
                    v-if="
                      answerInputList.indexOf(item.questionType) > -1
                        ? true
                        : false
                    "
                    >{{ val }}</span
                  >
                  <span
                    v-show="item.id == key"
                    v-if="item.questionType == 'CHECKBOX'"
                    >{{ scope.row.option["option_" + item.id] }}</span
                  >
                </div>
              </div>
              <div v-if="item.questionType == 'RADIO'">
                <div v-for="(menu, index) in item.optionList" :key="index">
                  <span
                    v-show="scope.row.option['option_' + item.id] == menu.id"
                    >{{ menu.option }}</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="operatorRemark"
            label="微信销售备注"
            width="130"
            align="center"
            show-overflow-tooltip
            v-if="roleMenu == 'SUPER_MANAGER'"
          ></el-table-column>
          <el-table-column
            prop="operatorRecordingList"
            label="微信销售质检"
            width="130"
            align="center"
            show-overflow-tooltip
            v-if="roleMenu == 'SUPER_MANAGER'"
          >
            <template slot-scope="scope">
              <el-tag
                v-for="tag in scope.row.operatorRecordingList"
                :key="tag.id"
                size="mini"
                class="tags_td"
                >{{ tag.label }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop
            label="操作"
            :width="roleMenu === 'SUPER_MANAGER' ? '270' : '230'"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="playRecord(scope.row)"
                >质检录音</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="downloadVoice(scope.row)"
                >下载录音</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="associatedRecords(scope.row)"
                >关联记录</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="dialogueTranslate(scope.row)"
                :disabled="scope.row.playVoiceDisabled"
                >对话翻译</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @current-change="currentPageWd_data"
          :current-page.sync="currentPageWd"
          :page-size="10"
          @size-change="sizeWdChange"
          :page-sizes="[10, 20, 30, 40]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="totalWd"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="回传质检" name="3">
        <div class="nav">
          <div class="calleeNumber">
            客户编号：
            <el-input
              v-model="callbackCondition.customerCode"
              placeholder="客户编号"
              clearable
            ></el-input>
          </div>
          <div class="calleeNumber">
            任务名称：
            <el-input
              v-model="callbackCondition.taskName"
              placeholder="任务名称"
              clearable
            ></el-input>
          </div>
          <div class="calleeNumber">
            最小轮次：
            <el-input
              v-model="callbackCondition.talkingRound"
              placeholder="最小轮次"
              clearable
            ></el-input>
          </div>
          <div class="calleeNumber">
            质检人员：
            <el-input
              v-model="callbackCondition.submitter"
              placeholder="质检人员"
              clearable
            ></el-input>
          </div>
          <div class="calleeNumber">
            城市：
            <el-input
              v-model="callbackCondition.city"
              placeholder="城市"
              clearable
            ></el-input>
          </div>
          <div class="calleeNumber">
            质检状态：
            <el-select
              v-model="callbackCondition.pass"
              placeholder="请选择质检状态"
              clearable
            >
              <el-option
                v-for="(item, index) in passList"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="calleeNumber">
            提交状态：
            <el-select
              v-model="callbackCondition.submitted"
              placeholder="请选择提交状态"
              clearable
            >
              <el-option
                v-for="(item, index) in submittedList"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="calleeNumber">
            标签：
            <el-select
              v-model="callbackCondition.tag"
              placeholder="请选择标签"
              clearable
              multiple
              collapse-tags
              @change="tagChange"
            >
              <el-option
                v-for="(item, index) in tagList"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="callerNumber" style="width: 530px">
            回传时间：
            <el-date-picker
              class="startTime"
              v-model="callbackCondition.startTime"
              type="datetime"
              placeholder="起始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              :clearable="false"
            ></el-date-picker>
            -
            <el-date-picker
              class="endTime"
              v-model="callbackCondition.endTime"
              type="datetime"
              placeholder="终止时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              :clearable="false"
            ></el-date-picker>
          </div>
          <div class="calleeNumber">
            我的质检：
            <el-checkbox
              v-model="callbackCondition.myQuality"
              @change="getCallbackTableData(1)"
            />
          </div>
          <div class="calleeNumber">
            复核：
            <el-select
              v-model="callbackCondition.recheck"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="(item, index) in recheckList"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div style="clear: both">
          <el-button type="primary" @click="getCallbackTableData(1)"
            >查询</el-button
          >
          <el-button
            v-if="roleMenu !== 'ZHIJIAN_OPERATOR'"
            type="primary"
            @click="exportCallbackTableData()"
            >导出</el-button
          >
          <el-button
            v-if="roleMenu !== 'ZHIJIAN_OPERATOR'"
            :disabled="multipleSelection.length == 0"
            type="primary"
            @click="batchSubmit()"
            >批量提交</el-button
          >
          <el-button type="primary" @click="shareQuality()">共享质检</el-button>
        </div>
        <!-- 待检列表 -->
        <el-table
          :data="callbackTableData"
          stripe
          style="width: 100%"
          border
          class="answer-class"
          v-loading="loadingTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            :selectable="
              (row) => {
                return row.pass == 0 || row.pass == 1;
              }
            "
            fixed="left"
          >
          </el-table-column>
          <el-table-column
            prop="customerCode"
            label="客户编号"
            width="120"
            align="center"
            fixed="left"
          >
          </el-table-column>
          <el-table-column
            prop="taskName"
            label="任务名称"
            width="200"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="pass"
            label="质检状态"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.pass == 1">通过</el-tag>
              <el-tag type="danger" v-else-if="scope.row.pass == 0"
                >不通过</el-tag
              >
              <el-tag type="info" v-else>未质检</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="tag"
            label="标签"
            align="center"
            width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="gender"
            label="性别"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="mobileProvince"
            label="城市"
            align="center"
            width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.mobileProvince }} {{ scope.row.mobileCity }}
            </template>
          </el-table-column>
          <el-table-column
            prop="area"
            label="区域"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="time"
            label="时长"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="talkingRound"
            label="轮次"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="need"
            label="需求"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="acceptFollowUp"
            label="回访"
            width="110"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="feedback"
            label="缺几颗牙"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="intentionLevelList"
            label="意向度问题"
            align="center"
            width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.intentionLevelList.join(",") }}
            </template>
          </el-table-column>
          <el-table-column
            prop="submitted"
            label="提交状态"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.submitted">已提交</el-tag>
              <el-tag type="warning" v-else>未提交</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="failReason"
            label="不成单原因"
            align="center"
            width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="submitter"
            label="质检人员"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="reviewer"
            label="二次质检人员"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            align="center"
            width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="gmtCreated"
            label="回传时间"
            width="170"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="submitterTime"
            label="首次质检时间"
            width="170"
            align="center"
          ></el-table-column>
          <el-table-column prop label="关键问题回答" min-width="200">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.keyIssueList" :key="index">
                <b style="color: black">{{ item.label }}:</b>
                {{ item.value }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop
            label="操作"
            align="center"
            fixed="right"
            width="120"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="
                  editRobotTelephoneCallbackQuality(
                    scope.row,
                    scope.$index,
                    'ONE'
                  )
                "
                >质检</el-button
              >
              <el-button
                v-if="roleMenu !== 'ZHIJIAN_OPERATOR'"
                type="text"
                size="small"
                @click="
                  editRobotTelephoneCallbackQuality(
                    scope.row,
                    scope.$index,
                    'TWO'
                  )
                "
                >二次质检</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @current-change="callbackCurrentPage_data"
          :current-page.sync="callbackCurrentPage"
          :page-size="callbackPageSize"
          @size-change="callbackSizeChange"
          :page-sizes="[10, 20, 30, 40]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="callbackTotal"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="问题表单" name="4">
        <div class="nav">
          <div class="calleeNumber" v-if="roleMenu !== 'ZHIJIAN_OPERATOR'">
            质检人员：
            <el-input
              v-model="qualityDiffCondition.submitter"
              placeholder="质检人员"
              clearable
            ></el-input>
          </div>
          <div class="callerNumber" style="width: 530px">
            回传时间：
            <el-date-picker
              class="startTime"
              v-model="qualityDiffCondition.startTime"
              type="datetime"
              placeholder="起始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              :clearable="false"
            ></el-date-picker>
            -
            <el-date-picker
              class="endTime"
              v-model="qualityDiffCondition.endTime"
              type="datetime"
              placeholder="终止时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              :clearable="false"
            ></el-date-picker>
          </div>
        </div>
        <div style="clear: both">
          <el-button type="primary" @click="getQualityDiffTableData(1)"
            >查询</el-button
          >
        </div>
        <!-- 待检列表 -->
        <el-table
          :data="qualityDiffTableData"
          stripe
          style="width: 100%"
          border
          class="answer-class"
          v-loading="loadingTable"
        >
          <el-table-column
            prop="customerCode"
            label="客户编号"
            width="120"
            align="center"
            fixed="left"
          >
          </el-table-column>
          <el-table-column
            prop="taskName"
            label="任务名称"
            width="200"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="pass"
            label="质检状态"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.pass == 1">通过</el-tag>
              <el-tag type="danger" v-else-if="scope.row.pass == 0"
                >不通过</el-tag
              >
              <el-tag type="info" v-else>未质检</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="tag"
            label="标签"
            align="center"
            width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="gender"
            label="性别"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="mobileProvince"
            label="城市"
            align="center"
            width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.mobileProvince }} {{ scope.row.mobileCity }}
            </template>
          </el-table-column>
          <el-table-column
            prop="area"
            label="区域"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="time"
            label="时长"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="talkingRound"
            label="轮次"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="need"
            label="需求"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="acceptFollowUp"
            label="回访"
            width="110"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="feedback"
            label="缺几颗牙"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="intentionLevelList"
            label="意向度问题"
            align="center"
            width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.intentionLevelList.join(",") }}
            </template>
          </el-table-column>
          <el-table-column
            prop="submitted"
            label="提交状态"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.submitted">已提交</el-tag>
              <el-tag type="warning" v-else>未提交</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="failReason"
            label="不成单原因"
            align="center"
            width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="submitter"
            label="质检人员"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="reviewer"
            label="二次质检人员"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            align="center"
            width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="gmtCreated"
            label="回传时间"
            width="170"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="submitterTime"
            label="首次质检时间"
            width="170"
            align="center"
          ></el-table-column>
          <el-table-column prop label="关键问题回答" min-width="200">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.keyIssueList" :key="index">
                <b style="color: black">{{ item.label }}:</b>
                {{ item.value }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop
            label="操作"
            align="center"
            fixed="right"
            width="120"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="
                  editRobotTelephoneCallbackQuality(
                    scope.row,
                    scope.$index,
                    'ONE'
                  )
                "
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @current-change="qualityDiffCurrentPage_data"
          :current-page.sync="qualityDiffCurrentPage"
          :page-size="qualityDiffPageSize"
          @size-change="qualityDiffSizeChange"
          :page-sizes="[10, 20, 30, 40]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="qualityDiffTotal"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>

    <!-- 质检录音 -->
    <el-dialog
      title="质检录音"
      :visible.sync="playRecordShow"
      width="1020px"
      @close="closeDialog"
      top="10vh"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">质检录音</span>
      </template>
      <div class="play_record_i">
        <div class="play_record_item">
          <div class="play_record_zhijian">质检标准</div>
          <el-input
            v-model="checkStandard"
            type="textarea"
            :rows="20"
            readonly
            style="width: 500px"
          ></el-input>
        </div>
        <div style="border: 1px solid #dcdfe6"></div>
        <div class="play_record_item">
          <el-form
            label-position="left"
            label-width="110px"
            :disabled="roleMenu == 'SUPER_MANAGER' ? false : true"
          >
            <el-form-item label="坐席编号：">
              <span style="font-weight: bold">{{ checkClientNumber }}</span>
              <el-divider direction="vertical"></el-divider>
              <span>客户编号：</span>
              <span style="font-weight: bold">{{ customerCode }}</span>
            </el-form-item>
            <el-form-item label="质量分析：">
              <template slot="label">
                质量分析
                <el-tooltip class="item" placement="top-start" effect="light">
                  <div slot="content">基于近7天数据分析统计</div>
                  <i class="el-icon-question icon-color"></i> </el-tooltip
                >：
              </template>
              <div class="item_zlfx">
                <el-tooltip class="item" placement="top-start" effect="light">
                  <div slot="content">
                    质检单数：{{ checkCount }} | 通过率：{{ passRate }}
                  </div>
                  <span style="font-weight: bold">{{ passRate }}</span>
                </el-tooltip>
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="item_zlfx">
                <el-tooltip class="item" placement="top-start" effect="light">
                  <div slot="content">{{ reasonSummaryInfo }}</div>
                  <span style="font-weight: bold">{{
                    reasonSummaryInfoLabel
                  }}</span>
                </el-tooltip>
              </div>
            </el-form-item>
            <el-form-item label="录音：">
              <audio
                ref="audio"
                :src="currentSrc"
                controlslist="nodownload"
                controls="controls"
              ></audio>
            </el-form-item>
            <el-form-item label="表单记录：">
              <el-button type="text" @click="historicalChanges"
                >查看表单</el-button
              >
            </el-form-item>
            <el-form-item label="标签：">
              <el-radio-group v-model="recordRadio">
                <el-radio :label="true">质检通过</el-radio>
                <el-radio :label="false">质检不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="失败原因：">
              <div class="error_cause_style">
                <div
                  style="
                    width: 200px;
                    border-right: 1px solid #e4e7ed;
                    padding-right: 10px;
                  "
                >
                  <el-button
                    type="text"
                    :disabled="errorCauseDisabled"
                    @click="errorCauseClick"
                    >失败原因</el-button
                  >
                  <div
                    v-for="item in errorCauseShowList"
                    :key="item.id"
                    style="line-height: 16px; margin-bottom: 10px"
                  >
                    {{ item.reason }}
                  </div>
                </div>
                <div style="width: 115px; padding-left: 10px">
                  <el-button
                    type="text"
                    :disabled="errorCauseDisabled"
                    @click="punishClick"
                    >处罚措施</el-button
                  >
                  <div>{{ punishValue }}</div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input
                v-model="recordRemark"
                type="textarea"
                :rows="8"
                placeholder="请输入备注"
                clearable
                style="width: 340px"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="recordQuality_submit"
            :disabled="roleMenu == 'SUPER_MANAGER' ? false : true"
            >确定</el-button
          >
          <el-button key="back" @click="playRecordShow = false">取消</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 录音格式 -->
    <el-dialog
      title="录音格式"
      :visible.sync="recordShow"
      width="500px"
      @close="closeRecord"
    >
      <template slot="title">
        <i class="el-icon-download dialog-title-my" style="color: #1296db"></i>
        <span class="dialog-title-my my-title">录音格式</span>
      </template>
      <el-form :inline="true" label-width="100px">
        <el-form-item label="录音格式:">
          <el-select
            v-model="voiceFileNameFormat"
            placeholder="请选择录音格式"
            clearable
          >
            <el-option
              v-for="item in voiceFileNameFormatList"
              :key="item.id"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="voiceFileNameChange"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 下载提示 -->
    <el-dialog
      :visible.sync="downloadTips"
      width="300px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeDownload"
      class="download-tips"
    >
      <div class="download-div">
        <i class="el-icon-loading loadingClass"></i>
        <span class="download-text">文件正在下载中...</span>
      </div>
    </el-dialog>
    <!-- 自定义过滤--待检 -->
    <el-dialog
      :visible.sync="selfFilterShow"
      title="自定义过滤"
      width="500px"
      @close="selfFilterClose"
      class="selfFilterClass"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">自定义过滤</span>
      </template>
      <el-form label-width="140px" label-position="right">
        <el-form-item
          v-for="item in projectQuestionList"
          :key="item.id"
          :label="item.question"
        >
          <el-input
            v-if="
              item.questionType == 'INPUT' ||
              item.questionType == 'TEXT' ||
              item.questionType == 'DISTRICT_SELECTOR' ||
              item.questionType == 'CAR_BRAND_SELECTOR'
            "
            :placeholder="'请输入' + item.question"
            v-model="options[item.id]"
            class="question_input"
          ></el-input>
          <el-select
            v-if="
              item.questionType == 'RADIO' || item.questionType == 'CHECKBOX'
            "
            v-model="optionCheckList[item.id]"
            multiple
            :placeholder="'请选择' + item.question"
            clearable
          >
            <el-option
              v-for="menu in item.optionList"
              :key="menu.id"
              :value="menu.id"
              :label="menu.option"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="selfFilterClick">确 定</el-button>
        <el-button @click="unselfFilterClick">取 消</el-button>
        <el-button @click="clearance">清空条件</el-button>
      </div>
    </el-dialog>
    <!-- 自定义过滤--我的 -->
    <el-dialog
      :visible.sync="selfFilter2Show"
      title="自定义过滤"
      width="500px"
      @close="selfFilter2Close"
      class="selfFilterClass"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">自定义过滤</span>
      </template>
      <el-form label-width="140px" label-position="right">
        <el-form-item
          v-for="item in projectQuestion2List"
          :key="item.id"
          :label="item.question"
        >
          <el-input
            v-if="
              item.questionType == 'INPUT' ||
              item.questionType == 'TEXT' ||
              item.questionType == 'DISTRICT_SELECTOR' ||
              item.questionType == 'CAR_BRAND_SELECTOR'
            "
            :placeholder="'请输入' + item.question"
            v-model="options2[item.id]"
            class="question_input"
          ></el-input>
          <el-select
            v-if="
              item.questionType == 'RADIO' || item.questionType == 'CHECKBOX'
            "
            v-model="optionCheck2List[item.id]"
            multiple
            :placeholder="'请选择' + item.question"
            clearable
          >
            <el-option
              v-for="menu in item.optionList"
              :key="menu.id"
              :value="menu.id"
              :label="menu.option"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="selfFilter2Click">确 定</el-button>
        <el-button @click="unselfFilter2Click">取 消</el-button>
        <el-button @click="clearance2">清空条件</el-button>
      </div>
    </el-dialog>
    <!-- 处罚措施 -->
    <el-dialog :visible.sync="punishShow" title="处罚措施" width="450px">
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">处罚措施</span>
      </template>
      <el-form label-position="left" label-width="110px">
        <el-form-item label="处罚措施：">
          <el-select
            v-model="punishSelect"
            placeholder="请选择处罚措施"
            style="width: 200px"
          >
            <el-option
              v-for="item in punishList"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button type="primary" @click="punish_submit">确定</el-button>
          <el-button @click="punishShow = false">取消</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 失败原因 -->
    <el-dialog :visible.sync="errorCauseShow" title="失败原因" width="500px">
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">失败原因</span>
      </template>
      <el-form label-position="left" label-width="110px">
        <el-form-item label="失败原因：">
          <el-select
            v-model="errorCause"
            multiple
            placeholder="请选择失败原因"
            style="width: 320px"
          >
            <el-option
              v-for="item in errorCauseList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button type="primary" @click="errorCause_submit">确定</el-button>
          <el-button @click="errorCauseShow = false">取消</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 查看历史变更 -->
    <el-dialog
      width="1360px"
      title="历史变更表单"
      :visible.sync="changeVisible"
      class="historical"
      @close="closeHistoricalDialog"
    >
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">历史变更表单</span>
      </template>
      <div class="changeClass tableChange">
        <el-table
          :data="tableDataHistor"
          stripe
          style="width: 100%"
          border
          size="mini"
          highlight-current-row
          @current-change="handleCurrentChange"
          ref="singleTable"
          v-loading="historLoading"
        >
          <el-table-column
            type="index"
            prop
            label="编号"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="clientNumber"
            label="坐席"
            min-width="100"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="gmtCreated"
            label="时间"
            min-width="150"
            align="left"
          ></el-table-column>
        </el-table>
      </div>
      <div class="changeClass formChange" v-show="historicalShow">
        <el-form label-position="right" class="projectQuestion-form">
          <el-form-item label="客户分类：" label-width="150px" class="flagImg">
            <el-radio-group v-model="disabledFlagList">
              <el-radio
                v-for="item in flagsFilter"
                :key="item.id"
                :label="item.id"
                disabled
              >
                <img :src="item.src" width="18px" />{{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-for="item in sipQuestionList"
            :key="item.id"
            :label="item.question"
            label-width="150px"
          >
            <el-radio-group
              v-if="item.questionType == 'RADIO'"
              v-model="optionsForm[item.id]"
            >
              <el-radio
                v-for="muen in item.optionList"
                :key="muen.id"
                :label="muen.id"
                disabled
                >{{ muen.option }}</el-radio
              >
            </el-radio-group>

            <el-input
              v-if="item.questionType == 'INPUT'"
              v-model="optionsForm[item.id]"
              :placeholder="'请输入' + item.question"
              style="width: 30%"
              disabled
            ></el-input>

            <el-input
              v-if="item.questionType == 'DISTRICT_SELECTOR'"
              v-model="optionsForm[item.id]"
              :placeholder="'请输入' + item.question"
              class="question_input"
              disabled
            ></el-input>

            <el-input
              v-if="item.questionType == 'CAR_BRAND_SELECTOR'"
              v-model="optionsForm[item.id]"
              :placeholder="'请输入' + item.question"
              class="question_input"
              disabled
            ></el-input>

            <el-input
              v-if="item.questionType == 'TEXT'"
              v-model="optionsForm[item.id]"
              :rows="8"
              type="textarea"
              resize="none"
              ref="remark"
              :placeholder="'请输入' + item.question"
              disabled
            ></el-input>

            <el-checkbox-group
              v-if="item.questionType == 'CHECKBOX'"
              v-model="optionsCheckList"
            >
              <el-checkbox
                v-for="muen in item.optionList"
                :key="muen.id"
                :label="muen.id"
                disabled
                >{{ muen.option }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button @click="historicalColse">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 关联记录表格 -->
    <el-dialog
      title="关联记录"
      :visible.sync="associatedRecordsShow"
      width="1200px"
      @close="associatedRecordsClose"
      class="records_style"
    >
      <el-table
        :data="associatedRecordsData"
        stripe
        border
        size="small"
        v-loading="loadingRecordsTable"
      >
        <el-table-column
          prop="batchNumber"
          label="批次编号"
          width="90"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="customerCode"
          label="客户编号"
          width="130"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="clientNumber"
          label="坐席编号"
          width="130"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="callSeconds"
          label="通话时长"
          width
          align="center"
        ></el-table-column>
        <el-table-column
          prop="connected"
          label="是否接通"
          width
          align="center"
        ></el-table-column>
        <el-table-column
          prop="callStartAt"
          label="开始时间"
          width="190"
          align="center"
        ></el-table-column>
        <el-table-column prop label="通话状态" width align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.callStatus }}</span>
            <i
              v-show="scope.row.callStatus == '通话中'"
              class="el-icon-loading"
            ></i>
          </template>
        </el-table-column>
        <el-table-column
          prop="routeName"
          label="线路名称"
          width="130"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="flagLable"
          label="客户分类"
          width="130"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="callbackTimes"
          label="回拨次数"
          width="130"
          align="center"
        ></el-table-column>
        <el-table-column
          prop
          label="操作"
          width="150"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="playCallRecord(scope.row)"
              :disabled="scope.row.playVoiceDisabled"
              >播放录音</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="downloadCallVoice(scope.row)"
              :disabled="scope.row.playVoiceDisabled"
              >下载录音</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="currentRecordersPageNo"
        :current-page.sync="pageNoRecorders"
        :page-size="10"
        :pager-count="5"
        layout="total, prev, pager, next, jumper"
        :total="recordsTotal"
      ></el-pagination>
    </el-dialog>

    <el-drawer
      title="质检"
      :visible.sync="passShow"
      direction="rtl"
      :size="formDiffData ? '1500px' : '1000px'"
      class="passDrawer"
      @close="passClose"
    >
      <div class="drawFlex">
        <div class="form oldForm" v-if="formDiffData">
          <div class="audio_div" style="text-align: center">上次质检信息</div>
          <el-form label-width="110px">
            <el-form-item label="性别:">
              <el-radio-group v-model="formDiffData.gender" disabled>
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="需求:">
              <el-radio-group v-model="formDiffData.need" disabled>
                <el-radio label="有需求1">有需求1</el-radio>
                <el-radio label="有需求2">有需求2</el-radio>
                <el-radio label="">空</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="回访:">
              <el-radio-group v-model="formDiffData.acceptFollowUp" disabled>
                <el-radio label="接受电话回访">电话</el-radio>
                <el-radio label="接受微信回访">微信</el-radio>
                <el-radio label="主动要求加微">主动</el-radio>
                <el-radio label="">空</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="缺几颗牙:">
              <el-input v-model="formDiffData.feedback" disabled></el-input>
            </el-form-item>
            <el-form-item label="区域:">
              <el-select v-model="formDiffData.area" disabled>
                <el-option
                  v-for="(item, index) in areaSelectList"
                  :key="index"
                  :label="item.area"
                  :value="item.area"
                >
                </el-option>
              </el-select>
              <span class="dropbox-ts" v-if="!formDiffData.areaPass">
                该区域不在范围内
              </span>
            </el-form-item>
            <el-form-item label="意向度问题:">
              <el-select
                v-model="formDiffData.intentionLevelList"
                multiple
                disabled
              >
                <el-option
                  v-for="item in intentionLevelSelectList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:">
              <el-input v-model="formDiffData.remark" disabled></el-input>
            </el-form-item>
            <el-form-item label="标签:">
              {{ formDiffData.tag }}
            </el-form-item>
            <el-form-item label="质检结果:">
              <el-radio-group v-model="formDiffData.pass" disabled>
                <el-radio :label="1">通过</el-radio>
                <el-radio :label="0">不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="formDiffData.pass == 0" label="不成单原因:">
              <el-checkbox-group v-model="failReasonList" disabled>
                <el-checkbox
                  :label="item"
                  v-for="(item, index) in failReasonSelectList"
                  :key="index"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item
              label="复核:"
              v-if="formDiffData.qualityType == 'TWO'"
            >
              <el-select v-model="formDiffData.recheck" disabled>
                <el-option
                  v-for="(item, index) in recheckList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="form">
          <div class="audio_div">
            <audio
              v-if="audioShow"
              ref="qualityAudio"
              :src="currentSrc"
              controls="controls"
              class="audio_class"
              @play="audioPlay"
              @pause="audioPause"
              @ended="audioEnded"
            ></audio>
          </div>
          <el-form label-width="110px">
            <el-form-item label="性别:">
              <el-radio-group
                v-model="qualityData.gender"
                :disabled="oneQualityDisabled"
              >
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="需求:">
              <el-radio-group
                v-model="qualityData.need"
                :disabled="oneQualityDisabled"
              >
                <el-radio label="有需求1">有需求1</el-radio>
                <el-radio label="有需求2">有需求2</el-radio>
                <el-radio label="">空</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="回访:">
              <el-radio-group
                v-model="qualityData.acceptFollowUp"
                :disabled="oneQualityDisabled"
              >
                <el-radio label="接受电话回访">电话</el-radio>
                <el-radio label="接受微信回访">微信</el-radio>
                <el-radio label="主动要求加微">主动</el-radio>
                <el-radio label="">空</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="缺几颗牙:">
              <el-input
                v-model="qualityData.feedback"
                placeholder="请输入缺几颗牙"
                :disabled="oneQualityDisabled"
              />
              <el-tooltip content="勾选后只需要输入数量" placement="right">
                <el-checkbox
                  v-if="shareQualityShow"
                  v-model="feedbackNumberCheck"
                  style="margin-left: 5px"
                />
              </el-tooltip>
            </el-form-item>
            <el-form-item label="区域:">
              <el-select
                v-model="qualityData.area"
                placeholder="请选择区域"
                @change="areaChange"
                filterable
                clearable
                :disabled="oneQualityDisabled"
              >
                <el-option
                  v-for="(item, index) in areaSelectList"
                  :key="index"
                  :label="item.area"
                  :value="item.area"
                >
                </el-option>
              </el-select>
              <span class="dropbox-ts" v-if="!qualityData.areaPass">
                该区域不在范围内
              </span>
            </el-form-item>
            <el-form-item label="意向度问题:">
              <el-select
                v-model="qualityData.intentionLevelList"
                ref="intentionLevel"
                multiple
                filterable
                allow-create
                default-1-option
                placeholder="请选择意向度问题"
                @mouseenter.native="toggleMenu"
                @visible-change="visibleChange"
                :disabled="oneQualityDisabled"
              >
                <el-option
                  v-for="item in intentionLevelSelectList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:">
              <el-input
                v-model="qualityData.remark"
                placeholder="请输入备注"
                :disabled="oneQualityDisabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="标签:">
              {{ qualityData.tag }}
            </el-form-item>
            <el-form-item label="质检结果:">
              <el-radio-group
                v-if="qualityData.pass == 0 || qualityData.pass == 1"
                v-model="qualityData.pass"
                :disabled="oneQualityDisabled"
              >
                <el-radio :label="1">通过</el-radio>
                <el-radio :label="0">不通过</el-radio>
              </el-radio-group>
              <el-button
                type="text"
                style="margin-left: 5px"
                @click="checkPass"
                v-if="!oneQualityDisabled"
                >查看</el-button
              >
            </el-form-item>
            <el-form-item v-if="qualityData.pass == 0" label="不成单原因:">
              <el-checkbox-group
                v-model="failReasonList"
                @change="failReasonChange"
                :disabled="oneQualityDisabled"
              >
                <el-checkbox
                  :label="item"
                  v-for="(item, index) in failReasonSelectList"
                  :key="index"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="复核:" v-if="qualityData.qualityType == 'TWO'">
              <el-select
                v-model="qualityData.recheck"
                placeholder="请选择"
                clearable
                :disabled="oneQualityDisabled"
              >
                <el-option
                  v-for="(item, index) in recheckList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div
            v-if="!oneQualityDisabled"
            style="
              display: flex;
              justify-content: center;
              position: absolute;
              bottom: 0;
              left: 0px;
              width: 430px;
              z-index: 999;
              background: #fff;
              padding: 10px 0 20px 0;
            "
          >
            <template v-if="shareQualityShow">
              <el-button type="danger" @click="addBlackList"
                >加黑名单</el-button
              >
              <el-button type="primary" @click="shareQualitySubmit"
                >下一个</el-button
              >
              <el-tooltip content="点击下一个自动提交" placement="left">
                <el-checkbox
                  :value="true"
                  style="margin-left: 20px; margin-top: 10px"
                  disabled
                />
              </el-tooltip>
            </template>
            <template v-else>
              <el-button type="danger" @click="addBlackList"
                >加黑名单</el-button
              >
              <el-button
                type="primary"
                :disabled="editRobotTelephoneCallbackQualityIndex == 0"
                @click="prevCallback"
                >上一个</el-button
              >
              <el-button
                type="primary"
                :disabled="
                  editRobotTelephoneCallbackQualityIndex ==
                  callbackTableData.length - 1
                "
                @click="nextCallback"
                >下一个</el-button
              >
              <el-button
                type="primary"
                @click="editRobotTelephoneCallbackQualitySubmit(false)"
                >确定</el-button
              >
              <el-tooltip content="点击下一个自动提交" placement="left">
                <el-checkbox
                  v-model="autoSubmit"
                  style="margin-left: 20px; margin-top: 10px"
                  @change="autoSubmitChange"
                />
              </el-tooltip>
            </template>
          </div>
        </div>
        <div class="drawer_time_line">
          <div class="dialogue_style">
            <ul class="dialogue_style_ul">
              <li
                v-for="(item, index) in dialogueList"
                :key="index"
                class="dialogue_style_li"
              >
                <!-- 返回话术 -->
                <div
                  v-if="item.customerChannelId == '1'"
                  class="dialogue_div_service"
                >
                  <div class="dialogue_style_service">
                    <div style="display: flex; justify-content: flex-start">
                      <div class="dialogue_style_service_avatar">
                        <el-avatar
                          size="small"
                          icon="el-icon-s-custom"
                        ></el-avatar>
                      </div>
                      <div class="dialogue_style_service_content">
                        {{ item.Text }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 发送话术 -->
                <div v-else class="dialogue_div_customer">
                  <div class="dialogue_style_customer">
                    <div style="display: flex; justify-content: flex-end">
                      <div class="dialogue_style_customer_content">
                        {{ item.Text }}
                      </div>
                      <div class="dialogue_style_customer_avatar">
                        <el-avatar
                          size="small"
                          icon="el-icon-service"
                        ></el-avatar>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 播放录音 -->
    <el-dialog
      class="customDialog"
      :visible.sync="playCallRecordShow"
      width="300px"
      @close="closeCallDialog"
    >
      <audio
        ref="audioCall"
        :src="currentCallSrc"
        autoplay="autoplay"
        controlsList="nodownload"
        controls="controls"
      ></audio>
    </el-dialog>
    <!-- 对话翻译 -->
  </div>
</template>

<script>
import dialogueTranslation from "../pages/dialogueTranslation";
import moment from "moment";
import lodash from "lodash";

export default {
  name: "qualityTestList",
  components: {
    dialogueTranslation,
  },
  data() {
    return {
      associatedRecordsShow: false,
      associatedRecordsData: [],
      loadingRecordsTable: false,
      pageNoRecorders: 1,
      recordsTotal: 0,
      callId: "",
      userLoginName: "",
      errorCauseShow: false,
      historLoading: false,
      historicalShow: false,
      server: "",
      tableDataHistor: [],
      changeVisible: false,
      disabledFlagList: "",
      optionsCheckList: [],
      sipQuestionList: [],
      optionsCheckList: [],
      optionsForm: {},
      skipHasBoughtLesson: true,
      punishSelect: "退单",
      punishValue: "退单",
      punishShow: false,
      punishList: [
        {
          value: "无",
        },
        {
          value: "退单",
        },
        {
          value: "下线培训",
        },
        {
          value: "罚单",
        },
        {
          value: "罚单(一罚三)",
        },
      ],
      reasonSummaryInfo: "",
      reasonSummaryInfoLabel: "",
      passRate: "",
      checkCount: "",
      checkClientNumber: "",
      errorCause: [],
      errorCauseList: [],
      errorCauseShowList: [],
      active: "1",
      callerUuid: "",
      questionTypeList: [
        "INPUT",
        "TEXT",
        "DISTRICT_SELECTOR",
        "CAR_BRAND_SELECTOR",
        "CHECKBOX",
      ],
      answerInputList: [
        "INPUT",
        "TEXT",
        "DISTRICT_SELECTOR",
        "CAR_BRAND_SELECTOR",
      ],
      teamNameList: [],
      loadingTable: false,
      selfFiltertext: "自定义过滤",
      optionCheckList: {},
      selfFilterShow: false,
      projectQuestionList: [],
      diyQuestionFilterList: [], // 自定意过滤问题筛选列表
      options: {},
      selfFiltertext2: "自定义过滤",
      optionCheck2List: {},
      selfFilter2Show: false,
      projectQuestion2List: [],
      diyQuestionFilter2List: [], // 自定意过滤问题筛选列表
      options2: {},
      flagsFilter: [
        { src: require("@/assets/img/flag_1.png"), label: "成单", id: "1" },
        { src: require("@/assets/img/flag_2.png"), label: "回拨", id: "2" },
        {
          src: require("@/assets/img/flag_3.png"),
          label: "非目标客群",
          id: "3",
        },
        { src: require("@/assets/img/flag_4.png"), label: "无意向", id: "4" },
      ],
      downloadTips: false,
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
      },
      loading: false,
      loadingTask: false,
      loadingName: false,
      flagList: [],
      downloadFormDisabled: true,
      exportRecordDisabled: true,
      optionTable: [],
      header: [],
      tableData: [],
      currentPage: 1,
      total: 0,
      callStatus_show: false,
      cancelShow: false,
      playRecordShow: false,
      audioShow: false,
      currentSrc: null, //录音url
      playFlag: true, //播放状态
      recordRadio: "",
      recordRemark: "",
      projectNameList: [],
      taskNameList: [],
      userNameList: [],
      headerId: {},
      customerCode: "",
      condition: {
        projectId: "",
        customerCode: "",
        clientNumber: "",
        taskId: "",
        flagslist: ["1"],
        callStartAtStart: "",
        callStartAtEnd: "",
        userName: "",
        batchNumber: "",
        flagModifiedAtStart: "",
        flagModifiedAtEnd: "",
        teamId: "",
        callMillisStart: "",
        callMillisEnd: "",
        wechatStatusList: [],
        recordingLabelList: [],
      },
      wechatStatusList: [
        {
          value: "NOT_FOUND",
          label: "未查询到好友",
        },
        {
          value: "SUBMITTED",
          label: "已提交好友申请",
        },
        {
          value: "SUBMIT_FAILURE",
          label: "好友申请异常",
        },
        {
          value: "ADDEDFRIEND",
          label: "已经是好友",
        },
        {
          value: "PASSED",
          label: "通过好友申请",
        },
        {
          value: "EXPIRED",
          label: "好友申请已过期",
        },
        {
          value: "RELOGIN",
          label: "登录失效",
        },
        {
          value: "CLIENT_OFFLINE",
          label: "手机离线",
        },
        {
          value: "NOTLOGGEDIN",
          label: "未登录",
        },
        {
          value: "NO_REQUEST",
          label: "未加微信",
        },
      ],
      conditionWd: {
        projectId: "",
        customerCode: "",
        clientNumber: "",
        taskId: "",
        flagslist: ["1"],
        callStartAtStart: "",
        callStartAtEnd: "",
        userName: "",
        batchNumber: "",
        flagModifiedAtStart: "",
        flagModifiedAtEnd: "",
        teamId: "",
        callMillisStart: "",
        callMillisEnd: "",
        conditionWd: "",
        recordingLabelList: [],
      },
      clientNumber: "",
      projectId: "",
      wdTableData: [],
      currentPageWd: 1,
      pageSizeWd: 10,
      totalWd: 0,
      registerName: "",
      pageSize: 10,
      recordShow: false,
      voiceFileNameFormatList: [
        {
          id: 1,
          value: "format1",
          label: "日期_坐席编号_客户编号",
        },
        {
          id: 2,
          value: "format3",
          label: "日期^客户编号",
        },
        {
          id: 3,
          value: "format2",
          label: "日期_坐席编号_手机号码",
        },
        {
          id: 4,
          value: "format4",
          label: "日期_手机号",
        },
        {
          id: 5,
          value: "format5",
          label: "900",
        },
        {
          id: 6,
          value: "format6",
          label: "手机号",
        },
      ],
      voiceFileNameFormat: "",
      voiceFileNameLabel: "录音格式",
      downloadTimer: null,
      downloadId: null,
      checkStandard: "",
      conditionWdList: [
        {
          value: "PASSED",
          label: "质检通过",
        },
        {
          value: "NO_CHECK",
          label: "未质检",
        },
        {
          value: "REJECTED",
          label: "质检不通过",
        },
      ],
      recordTagList: [],
      activeCustomerCode: "",
      activeBatchNumber: "",
      playCallRecordShow: false,
      currentCallSrc: "",
      playCallFlag: true,
      unifiedLoading: null,
      dialogueList: [],
      dialogueTranslateTitle: "",
      dialogueRound: false,
      callbackCondition: {
        customerCode: "",
        taskName: "",
        city: "",
        talkingRound: "4",
        pass: undefined,
        startTime: "",
        endTime: "",
        submitted: undefined,
        willingInfoSource: "A,B",
        tag: [],
        myQuality: false,
        submitter: "",
        recheck: undefined,
      },
      callbackTableData: [],
      callbackTotal: 0,
      multipleSelection: [],
      callbackPageSize: 10,
      callbackCurrentPage: 1,
      id: "",
      passList: [
        { label: "通过", value: 1 },
        { label: "不通过", value: 0 },
        { label: "未质检", value: 2 },
      ],
      submittedList: [
        { label: "已提交", value: true },
        { label: "未提交", value: false },
      ],
      tagList: [
        "全部",
        "待检项",
        "B4轮以下",
        "要求加微",
        "开场挽留+询问区域+B类+未询问问题",
        "杂音",
        "成功结束",
        "未成功结束",
        "开场白挽留",
        "询问问题 ",
        "未询问问题+A类",
        "未询问问题+B类",
      ],
      passShow: false,
      editRobotTelephoneCallbackQualityIndex: 0,
      autoSubmit: false,
      shareQualityShow: false,
      failReasonSelectList: [
        "不需要",
        "只问地址",
        "不缺牙",
        "杂音/未回答",
        "地区问题",
        "不正经",
        "已经种了",
        "骂人投诉",
        "年龄过大",
        "同行",
        "其他",
      ],
      areaMap: {
        成都: [
          { area: "锦江区", pass: true },
          { area: "天府新区", pass: true },
          { area: "青羊区", pass: true },
          { area: "金牛区", pass: true },
          { area: "武侯区", pass: true },
          { area: "高新区", pass: true },
          { area: "成华区", pass: true },
          { area: "龙泉驿区", pass: true },
          { area: "新都区", pass: true },
          { area: "温江区", pass: true },
          { area: "双流区", pass: true },
          { area: "郫都区", pass: true },
          { area: "青白江区", pass: false },
          { area: "新津区", pass: false },
          { area: "金堂县", pass: false },
          { area: "大邑县", pass: false },
          { area: "蒲江县", pass: false },
          { area: "都江堰市", pass: false },
          { area: "彭州市", pass: false },
          { area: "邛崃市", pass: false },
          { area: "崇州市", pass: false },
          { area: "简阳市", pass: false },
        ],
        昆明: [
          { area: "五华区", pass: true },
          { area: "盘龙区", pass: true },
          { area: "官渡区", pass: true },
          { area: "西山区", pass: true },
          { area: "呈贡区", pass: false },
          { area: "晋宁区", pass: false },
          { area: "东川区", pass: false },
          { area: "安宁市", pass: false },
          { area: "富民县", pass: false },
          { area: "宜良县", pass: false },
          { area: "嵩明县", pass: false },
          { area: "石林彝族自治县", pass: false },
          { area: "禄劝彝族苗族自治县", pass: false },
          { area: "寻甸回族彝族自治县", pass: false },
        ],
        泉州: [
          { area: "鲤城区", pass: true },
          { area: "丰泽区", pass: true },
          { area: "洛江区", pass: true },
          { area: "石狮市", pass: true },
          { area: "晋江市", pass: true },
          { area: "泉港区", pass: false },
          { area: "惠安县", pass: false },
          { area: "安溪县", pass: false },
          { area: "永春县", pass: false },
          { area: "德化县", pass: false },
          { area: "金门县", pass: false },
          { area: "南安市", pass: false },
        ],
        嘉兴: [
          { area: "南湖区", pass: true },
          { area: "秀洲区", pass: true },
          { area: "嘉善县", pass: true },
          { area: "海盐县", pass: true },
          { area: "平湖市", pass: true },
          { area: "桐乡市", pass: true },
          { area: "海宁市", pass: false },
        ],
        杭州: [
          { area: "上城区", pass: true },
          { area: "拱墅区", pass: true },
          { area: "西湖区", pass: true },
          { area: "滨江区", pass: true },
          { area: "萧山区", pass: true },
          { area: "余杭区", pass: false },
          { area: "富阳区", pass: false },
          { area: "临安区", pass: false },
          { area: "临平区", pass: false },
          { area: "桐庐县", pass: false },
          { area: "淳安县", pass: false },
          { area: "建德市", pass: false },
        ],
        厦门: [
          { area: "思明区", pass: true },
          { area: "海沧区", pass: true },
          { area: "湖里区", pass: true },
          { area: "集美区", pass: true },
          { area: "翔安区", pass: true },
          { area: "同安区", pass: false },
        ],
      },
      areaSelectList: [],
      intentionLevelSelectList: ["问价格1", "问价格2", "问地址"],
      failReasonList: [],
      formDiffData: null,
      qualityData: {
        callbackId: "",
        pass: 0,
        remark: "",
        gender: "男",
        area: undefined,
        tag: "",
        failReason: undefined,
        need: undefined,
        acceptFollowUp: undefined,
        feedback: "",
        intentionLevelList: [],
        qualityType: "ONE",
        areaPass: true,
        recheck: undefined,
        listeningDuration: 0,
        diff: false,
      },
      intentionLevelVisible: false,
      recheckList: ["复核不通过"],
      playTimeStamp: 0,
      endTimeStamp: 0,
      oneQualityDisabled: false,
      qualityAudioStatus: "",
      qualityDiffCondition: {
        startTime: "",
        endTime: "",
        submitter: "",
      },
      qualityDiffTableData: [],
      qualityDiffTotal: 0,
      qualityDiffPageSize: 10,
      qualityDiffCurrentPage: 1,
      feedbackNumberCheck: true,
    };
  },
  props: ["roleMenu", "loginName", "accountType"],
  computed: {
    teamSelectDisabled() {
      // 是否禁用队列名称
      if (this.active === "1") {
        if (this.roleMenu === "SUPER_MANAGER" && !this.condition.userName) {
          return true;
        } else {
          return false;
        }
      }
      if (this.active === "2") {
        if (this.roleMenu === "SUPER_MANAGER" && !this.conditionWd.userName) {
          return true;
        } else {
          return false;
        }
      }
    },
    isTeam() {
      // 是否为超级管理员，查询用户名称时查询队列
      if (this.roleMenu === "SUPER_MANAGER") {
        return false;
      } else {
        return true;
      }
    },
    errorCauseDisabled() {
      // 质检框失败原因选择
      if (this.recordRadio !== "") {
        if (!this.recordRadio) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  watch: {
    "$route.query.active": {
      immediate: true,
      handler(key) {
        this.active = key;
      },
    },
    active: {
      immediate: true,
      handler(key) {
        if (key == "0") {
          this.$router.replace({ query: { active: "1" } });
          return;
        }
        if (this.$route.query.active !== key) {
          this.$router.replace({ query: { active: key } });
        }
        switch (key) {
          case "3":
            this.callbackCondition.talkingRound = "4";
            this.callbackCondition.startTime = moment()
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss");
            this.callbackCondition.endTime = moment()
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss");
            this.getCallbackTableData(1);
            break;
          case "4":
            this.qualityDiffCondition.startTime = moment()
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss");
            this.qualityDiffCondition.endTime = moment()
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss");
            this.getQualityDiffTableData(1);
            break;
        }
      },
    },
  },
  mounted() {
    document.title = "惠销平台管理系统-质检列表";
    this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
    this.getRecordTagList();
    let strDate = new Date();
    strDate.setHours(0);
    strDate.setMinutes(0);
    strDate.setSeconds(0);
    strDate.setMilliseconds(0);
    strDate = this.$common.transformTime(strDate.valueOf());
    this.condition.callStartAtStart = strDate;
    this.condition.flagModifiedAtStart = strDate;
    this.conditionWd.callStartAtStart = strDate;
    this.conditionWd.flagModifiedAtStart = strDate;
    let autoSubmit = localStorage.getItem("autoSubmit");
    if (autoSubmit) {
      this.autoSubmit = JSON.parse(autoSubmit);
    }
  },
  methods: {
    qualityDiffCurrentPage_data(index) {
      this.getQualityDiffTableData(index);
    },
    qualityDiffSizeChange(pageSize) {
      this.qualityDiffPageSize = pageSize;
      this.getQualityDiffTableData(1);
    },
    getQualityDiffTableData(index) {
      if (index) {
        this.qualityDiffCurrentPage = index;
      }
      let data = {
        pageNo: this.qualityDiffCurrentPage,
        pageSize: this.qualityDiffPageSize,
        ...this.qualityDiffCondition,
      };

      this.loadingTable = true;
      this.$service(
        "/robotTelephoneCallbackQuality/qualityDiffPage",
        null,
        "get",
        null,
        data
      )
        .then((res) => {
          this.loadingTable = false;
          if (res.result == 200) {
            this.qualityDiffTableData = res.data.records;
            this.qualityDiffTableData.forEach((item) => {
              this.$set(item, "time", this.timeConsuming(item.callMillis));
            });
            this.qualityDiffTotal = res.data.total;
          }
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },
    callbackCurrentPage_data(index) {
      this.getCallbackTableData(index);
    },
    callbackSizeChange(pageSize) {
      this.callbackPageSize = pageSize;
      this.getCallbackTableData(1);
    },
    tagChange() {
      if (this.callbackCondition.tag.indexOf("全部") !== -1) {
        this.callbackCondition.tag = this.tagList.filter((item) => {
          return item !== "全部" && item !== "待检项";
        });
      } else if (this.callbackCondition.tag.indexOf("待检项") !== -1) {
        this.callbackCondition.tag = [
          "要求加微",
          "成功结束",
          "未成功结束",
          "询问问题 ",
          "未询问问题+A类",
        ];
      }
    },
    getCallbackTableData(index) {
      if (index) {
        this.callbackCurrentPage = index;
      }
      let data = {
        pageNo: this.callbackCurrentPage,
        pageSize: this.callbackPageSize,
        ...this.callbackCondition,
      };
      data.tag = data.tag.join(",");

      this.loadingTable = true;
      this.$service(
        "/robotTelephoneCallbackQuality/robotTelephoneCallbackQualityPage",
        null,
        "get",
        null,
        data
      )
        .then((res) => {
          this.loadingTable = false;
          if (res.result == 200) {
            this.callbackTableData = res.data.records;
            this.callbackTableData.forEach((item) => {
              this.$set(item, "time", this.timeConsuming(item.callMillis));
            });
            this.callbackTotal = res.data.total;
          }
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },
    batchSubmit() {
      let idList = this.multipleSelection.map((item) => {
        return item.id;
      });
      let data = { ids: idList.join(",") };
      this.$service("/robotTelephoneCallbackQuality/batchSubmit", data).then(
        (res) => {
          if (res.result == 200) {
            this.$message.success("提交成功");
            this.getCallbackTableData();
          }
        }
      );
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    timeConsuming(callMillis) {
      let seconds = callMillis;
      let hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      let minutes = Math.floor(seconds / 60);
      seconds %= 60;

      // 补零函数，使得数字始终为两位数
      const pad = (num) => (num < 10 ? "0" + num : num);

      return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    },
    exportCallbackTableData() {
      let params = {
        pageNo: this.callbackCurrentPage,
        pageSize: this.callbackPageSize,
        ...this.callbackCondition,
        exportSubmitted: false,
      };

      this.$confirm("导出时是否同步提交", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          params.exportSubmitted = true;
          this.exportCallbackTableDataSubmit(params);
        })
        .catch(() => {
          this.exportCallbackTableDataSubmit(params);
        });
    },
    exportCallbackTableDataSubmit(params) {
      let url = "";
      for (let i in params) {
        if ((params[i] || params[i] == false) && i !== "tag") {
          url += i + "=" + params[i] + "&";
        }
      }
      window.location.href =
        this.$constants.baseURL +
        "/robotTelephoneCallbackQuality/export?" +
        url +
        "tag=" +
        encodeURIComponent(this.callbackCondition.tag);
    },
    shareQuality() {
      this.shareQualityShow = true;
      this.unifiedLoading = this.$loading();
      this.$service("/robotTelephoneCallbackQuality/shareQuality", null, "get")
        .then((res) => {
          this.unifiedLoading.close();
          if (res.result === 200) {
            if (res.data) {
              this.id = res.data.id;
              this.qualityData = {
                callbackId: res.data.callbackId,
                pass: res.data.pass,
                remark: res.data.remark,
                gender: res.data.gender,
                area: res.data.area,
                tag: res.data.tag,
                failReason: res.data.failReason,
                need: res.data.need,
                acceptFollowUp: res.data.acceptFollowUp,
                feedback: res.data.feedback,
                intentionLevelList: res.data.intentionLevelList,
                qualityType: "ONE",
                areaPass: true,
                listeningDuration: 0,
              };
              if (res.data.failReason) {
                this.failReasonList = res.data.failReason.split(",");
              } else {
                this.failReasonList = [];
              }
              this.qualityAudioStatus = "";
              this.playTimeStamp = 0;
              this.endTimeStamp = 0;
              this.uuid = res.data.uuid;
              this.formDiffData = null;
              this.oneQualityDisabled = false;
              this.areaSelectList = this.areaMap[res.data.mobileCity];
              this.areaChange();
              this.customerCode = res.data.customerCode;
              this.dialogueList = [];
              this.dialogueTranslateTitle = "对话翻译";
              this.currentSrc = res.data.audioUrl;
              let list = res.data.chatContent;
              list.forEach((item) => {
                item.Text = item.content;
              });
              this.dialogueList = list;
              this.feedbackNumberCheck = true;
              this.passShow = true;
              setTimeout(() => {
                this.audioShow = true;
              }, 500);
            } else {
              this.$message.warring("没有数据了");
            }
          }
        })
        .catch((err) => {
          this.unifiedLoading.close();
        });
    },
    audioPlay() {
      this.qualityAudioStatus = "play";
      this.playTimeStamp = Date.now();
    },
    audioPause() {
      if (this.qualityAudioStatus != "pause") {
        this.computeListeningDuration();
        this.qualityAudioStatus = "pause";
      }
    },
    audioEnded() {
      if (this.qualityAudioStatus != "ended") {
        this.computeListeningDuration();
        this.qualityAudioStatus = "ended";
      }
    },
    computeListeningDuration() {
      if (this.playTimeStamp == 0) {
        return;
      }
      this.endTimeStamp = Date.now();
      let listeningDuration =
        this.qualityData.listeningDuration +
        this.endTimeStamp -
        this.playTimeStamp;
      this.playTimeStamp = 0;
      this.endTimeStamp = 0;
      console.log(listeningDuration);
      this.$set(this.qualityData, "listeningDuration", listeningDuration);
    },
    calibrationListeningDuration() {
      this.qualityAudioStatus = "pause";
      this.$refs.qualityAudio.pause();
      if (this.playTimeStamp !== 0 && this.endTimeStamp == 0) {
        this.computeListeningDuration();
      }
    },
    passClose() {
      if (this.shareQualityShow) {
        let data = {
          id: this.qualityData.callbackId,
        };
        this.$service(
          "/robotTelephoneCallbackQuality/cancelQuality",
          data
        ).then((res) => {
          if (res.result == 200) {
            this.getCallbackTableData();
          }
        });
      }
    },
    checkPass() {
      let data = {
        ...this.qualityData,
      };
      if (this.shareQualityShow) {
        data.id = this.id;
        data.uuid = this.uuid;
        data.model = "SHARE";
      }
      if (data.pass == 1) {
        data.failReason = undefined;
      }
      if (this.feedbackNumberCheck && data.feedback) {
        data.feedback = "缺" + data.feedback + "颗";
      }
      this.$service("/robotTelephoneCallbackQuality/checkPass", data).then(
        (res) => {
          if (res.result == 200) {
            this.$set(this.qualityData, "pass", res.data ? 1 : 0);
          }
        }
      );
    },
    toggleMenu() {
      if (!this.intentionLevelVisible) {
        this.$refs.intentionLevel.toggleMenu();
      }
    },
    visibleChange(visible) {
      this.intentionLevelVisible = visible;
    },
    areaChange() {
      if (this.qualityData.area) {
        let areaPass = this.areaSelectList.filter((item) => {
          return item.area == this.qualityData.area;
        })[0].pass;
        this.$set(this.qualityData, "areaPass", areaPass);
      }
    },
    failReasonChange() {
      this.$set(this.qualityData, "failReason", this.failReasonList.join(","));
    },
    shareQualitySubmit() {
      this.calibrationListeningDuration();

      let data = {
        id: this.id,
        uuid: this.uuid,
        model: "SHARE",
        ...this.qualityData,
      };
      if (data.pass == 1) {
        data.failReason = undefined;
      }
      this.$service(
        "/robotTelephoneCallbackQuality/editRobotTelephoneCallbackQuality",
        data
      ).then((res) => {
        if (res.result == 200) {
          this.$message.success("操作成功");
          this.getCallbackTableData();
          this.shareQuality();
        }
      });
    },
    prevCallback() {
      this.editRobotTelephoneCallbackQualityIndex--;
      this.editRobotTelephoneCallbackQuality(
        this.callbackTableData[this.editRobotTelephoneCallbackQualityIndex],
        this.editRobotTelephoneCallbackQualityIndex
      );
    },
    nextCallback() {
      this.editRobotTelephoneCallbackQualityIndex++;
      if (this.autoSubmit) {
        this.editRobotTelephoneCallbackQualitySubmit(true);
      } else {
        this.editRobotTelephoneCallbackQuality(
          this.callbackTableData[this.editRobotTelephoneCallbackQualityIndex],
          this.editRobotTelephoneCallbackQualityIndex
        );
      }
    },
    addBlackList() {
      this.$confirm("加黑名单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            customerCode: this.customerCode,
            sources: "高投诉意向人群(回复)",
          };

          this.$http("/blacklist/add", data).then((res) => {
            if (res.result == 200) {
              this.$message.success("操作成功");
            }
          });
        })
        .catch(() => {});
    },
    async editRobotTelephoneCallbackQuality(val, index, qualityType) {
      this.shareQualityShow = false;
      this.qualityData = {
        callbackId: val.id,
        pass: val.pass,
        remark: val.remark,
        gender: val.gender,
        area: val.area,
        tag: val.tag,
        failReason: val.failReason,
        need: val.need,
        acceptFollowUp: val.acceptFollowUp,
        feedback: val.feedback,
        intentionLevelList: val.intentionLevelList,
        qualityType: qualityType ? qualityType : this.qualityData.qualityType,
        areaPass: true,
        listeningDuration: 0,
      };
      this.qualityAudioStatus = "";
      this.playTimeStamp = 0;
      this.endTimeStamp = 0;
      if (this.qualityData.qualityType == "TWO") {
        let oldData = lodash.cloneDeep(this.qualityData);
        delete oldData.listeningDuration;
        this.$set(this.qualityData, "oldDataJson", JSON.stringify(oldData));
        this.$set(this.qualityData, "recheck", val.recheck);
      }
      if (val.failReason) {
        this.failReasonList = val.failReason.split(",");
      } else {
        this.failReasonList = [];
      }
      this.areaSelectList = this.areaMap[val.mobileCity];
      this.areaChange();
      this.customerCode = val.customerCode;
      this.editRobotTelephoneCallbackQualityIndex = index;
      this.passShow = true;
      let data = {
        id: val.id,
      };
      this.unifiedLoading = this.$loading();
      this.formDiffData = null;
      this.oneQualityDisabled = false;
      this.feedbackNumberCheck = false;

      if (this.qualityData.qualityType == "ONE") {
        if (val.reviewer) {
          this.oneQualityDisabled = true;
        }
        await this.$service(
          "/robotTelephoneCallbackQuality/formDiff",
          null,
          "get",
          null,
          { callbackId: val.id }
        ).then((res) => {
          if (res.result === 200) {
            this.formDiffData = res.data;
            if (this.formDiffData) {
              if (this.formDiffData.area) {
                let areaPass = this.areaSelectList.filter((item) => {
                  return item.area == this.formDiffData.area;
                })[0].pass;
                this.$set(this.formDiffData, "areaPass", areaPass);
              } else {
                this.$set(this.formDiffData, "areaPass", true);
              }
            }
          }
        });
      }

      this.$service(
        "/robotTelephoneCallbackQuality/chatContent",
        null,
        "get",
        null,
        data
      )
        .then((res) => {
          this.unifiedLoading.close();
          if (res.result === 200) {
            this.dialogueList = [];
            this.dialogueTranslateTitle = "对话翻译-" + val.customerCode;
            this.currentSrc = res.data.audioUrl;
            let list = res.data.chatContent;
            list.forEach((item) => {
              item.Text = item.content;
            });
            this.dialogueList = list;
            setTimeout(() => {
              this.audioShow = true;
            }, 500);
          }
        })
        .catch((err) => {
          this.unifiedLoading.close();
        });
    },
    editRobotTelephoneCallbackQualitySubmit(next) {
      this.calibrationListeningDuration();

      if (this.qualityData.qualityType == "TWO") {
        let newData = lodash.cloneDeep(this.qualityData);
        delete newData.listeningDuration;
        delete newData.recheck;
        delete newData.oldDataJson;
        let newDataJson = JSON.stringify(newData);
        let diff = false;
        if (newDataJson !== this.qualityData.oldDataJson) {
          diff = true;
        }
        delete this.qualityData.oldDataJson;
        this.$set(this.qualityData, "diff", diff);
      }

      let data = { ...this.qualityData };

      if (data.pass == 1) {
        data.failReason = undefined;
      }

      this.$service(
        "/robotTelephoneCallbackQuality/editRobotTelephoneCallbackQuality",
        data
      ).then((res) => {
        if (res.result == 200) {
          this.$message.success("操作成功");
          this.getCallbackTableData();
          if (next) {
            this.editRobotTelephoneCallbackQuality(
              this.callbackTableData[
                this.editRobotTelephoneCallbackQualityIndex
              ],
              this.editRobotTelephoneCallbackQualityIndex
            );
          }
        }
      });
    },
    autoSubmitChange() {
      localStorage.setItem("autoSubmit", JSON.stringify(this.autoSubmit));
    },
    // 点击对话翻译
    dialogueTranslate(val) {
      let data = {
        userLoginName: val.userLoginName,
        uuid: val.callerUuid,
      };
      this.unifiedLoading = this.$loading();
      let urlServer = "https://" + val.server + this.$constants.basePath;
      this.$http("/sip/call/translationInfo", null, "get", urlServer, data)
        .then((res) => {
          this.unifiedLoading.close();
          if (res.result === 200) {
            this.currentSrc = `https://${val.server}${this.$constants.basePath}/sip/playVoice?callerUuid=${val.callerUuid}&loginName=${val.userLoginName}`;
            this.dialogueList = [];
            this.dialogueTranslateTitle = "对话翻译-" + val.customerCode;
            if (val.clientNumber) {
              this.dialogueTranslateTitle += "-" + val.clientNumber;
            }
            this.dialogueRound = res.data.dialogueRound;
            if (res.data.dialogueRound) {
              if (!res.data || res.data.status !== "SUCCESS") {
                this.$message.warning("对话未翻译");
                return;
              }
              // this.dialogueTranslateShow = true;
              let list = JSON.parse(res.data.list.content);
              list.forEach((item) => {
                item.customerChannelId = res.data.list.customerChannelId;
              });
              this.dialogueList = list;
            } else {
              let list = JSON.parse(res.data.list.content);
              list.forEach((item) => {
                item.customerChannelId = res.data.list.customerChannelId;
              });
              this.dialogueList = list;
              // this.dialogueList = res.data.list;
            }
            this.$refs.dialogueTranslation.speechTestClick();
          }
        })
        .catch((err) => {
          this.unifiedLoading.close();
        });
    },
    playCallRecord(val) {
      //播放录音
      this.playCallRecordShow = true;
      let callId = val.id;
      let path = "https://" + this.server + this.$constants.basePath;
      this.currentCallSrc = `${path}/sip/playVoice?callId=${callId}&loginName=${val.userLoginName}`;
      let lastRunTime = Date.now(),
        currentTime = Date.now(),
        protectTime = 100;
      if (currentTime - lastRunTime < protectTime) {
        return; //两次执行太过频繁，直接退出
      }
      if (this.playCallFlag == false) {
        this.$refs.audioCall.play();
        this.playCallFlag = true;
      }
    },
    downloadCallVoice(val) {
      //下载录音
      let path = "https://" + this.server + this.$constants.basePath;
      let href =
        path +
        "/sip/downloadVoice?callId=" +
        val.id +
        "&loginName=" +
        val.userLoginName +
        "&voiceFileNameFormat=format1";
      window.location.href = href;
    },
    closeCallDialog() {
      //播放录音关闭
      this.$refs.audioCall.pause();
      this.playCallFlag = false;
    },
    // 点击关联记录
    associatedRecords(val) {
      this.activeCustomerCode = val.customerCode;
      this.activeBatchNumber = val.batchNumber;
      this.server = val.server;
      this.associatedRecordsShow = true;
      this.inquireRecords(1);
    },
    // 查询关联记录
    inquireRecords(index) {
      let data = {
        pageNo: index,
        pageSize: 10,
        customerCode: this.activeCustomerCode,
        batchNumber: this.activeBatchNumber,
      };
      this.loadingRecordsTable = true;
      let path = "https://" + this.server + this.$constants.basePath;
      this.$http("/sip/call/list", null, "get", path, data)
        .then((res) => {
          this.loadingRecordsTable = false;
          if (res.result == 200) {
            let list = res.data.list;
            this.recordsTotal = res.data.count;
            list.map((item) => {
              //数据转换
              item.callStartAt = this.$common.transformTime(item.callStartAt);
              if (item.callSeconds == -1) {
                item.callSeconds = "-";
              } else {
                item.callSeconds = `${item.callSeconds} 秒`;
              }
              if (item.ringSeconds == -1) {
                item.ringSeconds = "-";
              } else {
                item.ringSeconds = `${item.ringSeconds} 秒`;
              }
              if (item.distributeSeconds == -1) {
                item.distributeSeconds = "-";
              } else {
                item.distributeSeconds = `${item.distributeSeconds} 秒`;
              }
              switch (item.callStatus) {
                case "CONNECTED":
                  item.callStatus = "接通";
                  item.playVoiceDisabled = false;
                  break;
                case "NO_ANSWER":
                  item.callStatus = "无人接听";
                  break;
                case "USER_BUSY":
                  item.callStatus = "占线";
                  break;
                case "EXCEPTION":
                  item.callStatus = "无法接通";
                  break;
              }
              switch (item.distributed) {
                case true:
                  item.distributed = "是";
                  break;
                case false:
                  item.distributed = "否";
                  break;
              }
              switch (item.connected) {
                case true:
                  item.connected = "是";
                  break;
                case false:
                  item.connected = "否";
                  break;
              }
              switch (item.flag) {
                case 1:
                  item.flagLable = "成单";
                  break;
                case 2:
                  item.flagLable = "回拨";
                  break;
                case 3:
                  item.flagLable = "非目标客群";
                  break;
                case 4:
                  item.flagLable = "无意向";
                  break;
              }
            });
            this.associatedRecordsData = list;
          }
        })
        .catch((err) => {
          this.loadingRecordsTable = false;
        });
    },
    // 关联记录分页
    currentRecordersPageNo(index) {
      this.inquireRecords(index);
    },
    // 关闭关联记录
    associatedRecordsClose() {
      this.associatedRecordsData = [];
    },
    // 获取录音质检标签
    getRecordTagList() {
      this.$http("/wechat/labelList", null, "get", null, {
        category: "RECORDING",
      }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.recordTagList = list;
        }
      });
    },
    // 点击查看历史表单
    historicalChanges() {
      this.changeVisible = true;
      let params = {
        callId: this.callId,
        pageNo: 1,
        pageSize: 6,
        clientNumber: this.clientNumber,
        loginName: this.userLoginName,
        callerUuid: this.callerUuid,
      };
      this.historLoading = true;
      let path = "https://" + this.server + this.$constants.basePath;
      this.$http("/sip/question/answerHistory", null, "get", path, params).then(
        (res) => {
          this.historLoading = false;
          if (res.result == 200) {
            let list = res.data;
            list.map((item) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              if (item.sipQuestionList && item.sipQuestionList.length > 0) {
                item.sipQuestionList.map((menu) => {
                  menu.question = menu.question + "：";
                });
              }
            });
            this.tableDataHistor = list;
            this.setCurrent(this.tableDataHistor[0]);
          }
        }
      );
    },
    historicalColse() {
      //点击关闭查看历史变更
      this.changeVisible = false;
    },
    setCurrent(row) {
      //选中的行
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      //选择历史
      let disabledFlagList = [];
      if (val) {
        this.optionsForm = {};
        this.historicalShow = true;
        this.disabledFlagList = "";
        this.optionsCheckList = [];
        let flags = JSON.parse(val.flags);
        if (flags) {
          for (let key in flags) {
            disabledFlagList.push(key);
          }
        }
        disabledFlagList = disabledFlagList.join(",");
        this.disabledFlagList = disabledFlagList.substring(0, 1);
        let sipQuestionList = val.sipQuestionList;
        if (sipQuestionList && sipQuestionList.length > 0) {
          sipQuestionList.map((item) => {
            if (item.optionList) {
              item.optionList.map((menu) => {
                menu.id = menu.id.toString();
              });
            }
          });
        }
        let answer = JSON.parse(val.answer);
        let optionsCheck = "";
        this.sipQuestionList = sipQuestionList;
        for (let i in answer) {
          optionsCheck += answer[i] + ",";
          for (let j in sipQuestionList) {
            if (i == sipQuestionList[j].id) {
              this.optionsForm[i] = answer[i];
            }
          }
        }
        this.optionsCheckList = optionsCheck.split(",");
      }
    },
    closeHistoricalDialog() {
      //关闭历史弹窗
      this.historicalShow = false;
      this.historLoading = false;
      this.tableDataHistor = [];
    },
    // 点击失败原因
    errorCauseClick() {
      this.errorCauseShow = true;
      this.errorCause = [];
      this.errorCauseShowList.forEach((item) => {
        this.errorCause.push(item.id);
      });
    },
    // 确定失败原因
    errorCause_submit() {
      this.errorCauseShowList = [];
      this.errorCause.forEach((menu) => {
        this.errorCauseList.forEach((item) => {
          if (menu === item.id) {
            this.errorCauseShowList.push(item);
          }
        });
      });
      this.errorCauseShow = false;
    },
    // 点击处罚措施
    punishClick() {
      this.punishShow = true;
      this.punishSelect = this.punishValue;
    },
    // 确认处罚措施
    punish_submit() {
      this.punishValue = this.punishSelect;
      this.punishShow = false;
    },
    // 查询失败原因
    getFailureAnalysis() {
      let startTime = new Date();
      startTime.setHours(0);
      startTime.setMinutes(0);
      startTime.setSeconds(0);
      startTime.setMilliseconds(0);
      startTime = startTime - 60 * 60 * 24 * 7 * 1000;
      startTime = this.$common.transformTime(startTime.valueOf());
      let data = {
        projectId: this.projectId,
        clientNumber: this.clientNumber,
        startTime: startTime,
      };
      this.$http(
        "/sip/questionnaire/failureAnalysis",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          this.passRate = res.data.passRate;
          this.checkCount = res.data.checkCount;
          this.reasonSummaryInfo = "";
          if (res.data.reasonSummaryInfoList) {
            let list = [];
            res.data.reasonSummaryInfoList.forEach((item) => {
              let reasonLabel = item.reason + "：" + item.rate;
              list.push(reasonLabel);
            });
            this.reasonSummaryInfo = list.join(" | ");
            this.reasonSummaryInfoLabel = this.reasonSummaryInfo;
            if (this.reasonSummaryInfo.length > 18) {
              this.reasonSummaryInfoLabel =
                this.reasonSummaryInfo.substring(0, 18) + "...";
            }
          }
        }
      });
    },
    // 查询队列
    getTeamList(data) {
      this.$http("/sip/team/list", null, "get", null, data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.teamNameList = list;
        }
      });
    },
    //清空自定意过滤条件--待检
    clearance() {
      this.options = {};
      this.optionCheckList = {};
    },
    //清空自定意过滤条件--我的
    clearance2() {
      this.options2 = {};
      this.optionCheck2List = {};
    },
    //查询问题--待检
    projectQuestionWithCache(projectId) {
      this.diyQuestionFilterList = [];
      let params = {
        projectId: projectId,
      };
      this.$http(
        "/sip/question/projectQuestionList",
        null,
        "get",
        null,
        params
      ).then((res) => {
        if (res.result == 200) {
          this.optionCheckList = {};
          this.projectQuestionList = res.data.sipQuestionList;
          if (this.projectQuestionList) {
            this.projectQuestionList.map((item) => {
              if (
                item.questionType == "CHECKBOX" ||
                item.questionType == "RADIO"
              ) {
                this.$set(this.optionCheckList, item.id, []);
              }
            });
          }
        }
      });
    },
    //查询问题--我的
    projectQuestionWithCache2(projectId) {
      this.diyQuestionFilter2List = [];
      let params = {
        projectId: projectId,
      };
      this.$http(
        "/sip/question/projectQuestionList",
        null,
        "get",
        null,
        params
      ).then((res) => {
        if (res.result == 200) {
          this.optionCheck2List = {};
          this.projectQuestion2List = res.data.sipQuestionList;
          if (this.projectQuestion2List) {
            this.projectQuestion2List.map((item) => {
              if (
                item.questionType == "CHECKBOX" ||
                item.questionType == "RADIO"
              ) {
                this.$set(this.optionCheck2List, item.id, []);
              }
            });
          }
        }
      });
    },
    //点击自定意过滤--待检
    firstSelfFiltering() {
      let isHttp = false;
      if (this.condition.projectId) {
        isHttp = true;
      }
      if (isHttp) {
        // 根据自定意过滤问题筛选列表来填充自定意过滤表单
        this.selfFilterShow = true;
        if (this.diyQuestionFilterList.length > 0) {
          let list = this.diyQuestionFilterList;
          for (const i in list) {
            if (list[i].type === "INPUT") {
              this.options[list[i].questionId] = list[i].answer;
            } else if (list[i].type === "CHECKBOX") {
              let arr = list[i].answer.split(",");
              let arr1 = [];
              if (list[i].answer) {
                arr.map((c) => {
                  c = Number(c);
                  arr1.push(c);
                });
              }
              this.$set(this.optionCheckList, list[i].questionId, arr1);
            }
          }
        }
      } else {
        this.$message({ message: "请选择项目", type: "warning" });
      }
    },
    //确定自定意过滤--待检
    selfFilterClick() {
      this.diyQuestionFilterList = []; // 自定意过滤问题筛选列表
      let list = this.projectQuestionList;
      let textList = []; // 显示自定义过滤文字
      for (let i in this.options) {
        // INPUT类型
        if (this.options[i]) {
          list.forEach((c) => {
            if (c.id == i) {
              textList.push(c.question + ":" + this.options[i]);
            }
          });
          let data = {
            questionId: i,
            type: "INPUT",
            answer: this.options[i],
          };
          this.diyQuestionFilterList.push(data);
        }
      }
      for (let j in this.optionCheckList) {
        // CHECKBOX类型
        if (this.optionCheckList[j].length > 0) {
          list.forEach((k) => {
            if (k.id == j) {
              let text = [];
              k.optionList.forEach((d) => {
                this.optionCheckList[j].forEach((a) => {
                  if (a == d.id) {
                    text.push(d.option);
                  }
                });
              });
              textList.push(k.question + ":" + text);
            }
          });
          let item = {
            questionId: j,
            type: "CHECKBOX",
            answer: this.optionCheckList[j].join(","),
          };
          this.diyQuestionFilterList.push(item);
        }
      }
      if (textList.length > 0) {
        let selfText = textList.join("|");
        this.selfFiltertext = selfText;
        if (selfText.length > 30) {
          // 长度超过30个字符
          this.selfFiltertext = selfText.substr(0, 30) + "...";
        }
      } else {
        this.selfFiltertext = "自定义过滤";
      }
      this.selfFilterShow = false;
    },
    //取消自定意过滤--待检
    unselfFilterClick() {
      this.selfFilterShow = false;
    },
    //关闭自定意过滤--待检
    selfFilterClose() {
      // this.projectQuestionList = []
    },
    //点击自定意过滤--我的
    secondSelfFiltering() {
      let isHttp = false;
      if (this.conditionWd.projectId) {
        isHttp = true;
      }
      if (isHttp) {
        // 根据自定意过滤问题筛选列表来填充自定意过滤表单
        this.selfFilter2Show = true;
        if (this.diyQuestionFilter2List.length > 0) {
          let list = this.diyQuestionFilter2List;
          for (const i in list) {
            if (list[i].type === "INPUT") {
              this.options2[list[i].questionId] = list[i].answer;
            } else if (list[i].type === "CHECKBOX") {
              let arr = list[i].answer.split(",");
              let arr1 = [];
              if (list[i].answer) {
                arr.map((c) => {
                  c = Number(c);
                  arr1.push(c);
                });
              }
              this.$set(this.optionCheck2List, list[i].questionId, arr1);
            }
          }
        }
      } else {
        this.$message({ message: "请选择项目", type: "warning" });
      }
    },
    //确定自定意过滤--我的
    selfFilter2Click() {
      this.diyQuestionFilter2List = []; // 自定意过滤问题筛选列表
      let list = this.projectQuestion2List;
      let textList = []; // 显示自定义过滤文字
      for (let i in this.options2) {
        // INPUT类型
        if (this.options2[i]) {
          list.forEach((c) => {
            if (c.id == i) {
              textList.push(c.question + ":" + this.options2[i]);
            }
          });
          let data = {
            questionId: i,
            type: "INPUT",
            answer: this.options2[i],
          };
          this.diyQuestionFilter2List.push(data);
        }
      }
      for (let j in this.optionCheck2List) {
        // CHECKBOX类型
        if (this.optionCheck2List[j].length > 0) {
          list.forEach((k) => {
            if (k.id == j) {
              let text = [];
              k.optionList.forEach((d) => {
                this.optionCheck2List[j].forEach((a) => {
                  if (a == d.id) {
                    text.push(d.option);
                  }
                });
              });
              textList.push(k.question + ":" + text);
            }
          });
          let item = {
            questionId: j,
            type: "CHECKBOX",
            answer: this.optionCheck2List[j].join(","),
          };
          this.diyQuestionFilter2List.push(item);
        }
      }
      if (textList.length > 0) {
        let selfText = textList.join("|");
        this.selfFiltertext2 = selfText;
        if (selfText.length > 30) {
          // 长度超过30个字符
          this.selfFiltertext2 = selfText.substr(0, 30) + "...";
        }
      } else {
        this.selfFiltertext2 = "自定义过滤";
      }
      this.selfFilter2Show = false;
    },
    //取消自定意过滤--我的
    unselfFilter2Click() {
      this.selfFilter2Show = false;
    },
    //关闭自定意过滤--我的
    selfFilter2Close() {
      // this.projectQuestion2List = []
    },
    //关闭下载提示
    closeDownload() {
      if (this.downloadTimer) {
        clearInterval(this.downloadTimer);
        this.downloadTimer = null;
      }
    },
    //刷新下载状态
    downloadStatus() {
      this.downloadTimer = setInterval(() => {
        this.$http("/sip/downloadFile/checkDownloadStatus", null, "get", null, {
          id: this.downloadId,
        }).then((res) => {
          if (res.result == 200) {
            let status = res.data;
            if (status === "DOWNLOADABLE") {
              // let href = this.$constants.baseURL+"/sip/downloadFile/download?id="+this.downloadId;
              // window.location.href = href;
              this.$http(
                "/sip/downloadFile/getRemoteDownloadUrl",
                null,
                "get",
                null,
                { id: this.downloadId }
              ).then((res) => {
                clearInterval(this.downloadTimer);
                this.downloadTimer = null;
                this.downloadTips = false;
                if (res.result == 200) {
                  let href = res.data;
                  window.location.href = href;
                }
              });
            } else if (status === "EXCEPTION") {
              clearInterval(this.downloadTimer);
              this.downloadTimer = null;
              this.downloadTips = false;
              this.$message.error("下载失败");
            }
          } else {
            this.downloadTips = false;
            clearInterval(this.downloadTimer);
            this.downloadTimer = null;
          }
        });
      }, 2000);
    },
    flexColumnWidth(val, type) {
      //表格列宽
      let flexWidth = null;
      let widthList = [];
      if (this.tableData.length > 0) {
        this.tableData.forEach((item) => {
          // 问卷有值
          if (item.answerMap) {
            for (let i in item.answerMap) {
              if (val.id == i) {
                // 有答案
                if (val.question.length >= item.answerMap[i].length) {
                  // 表头和内容字数一样按表头计算
                  flexWidth = this.$common.calculatedLength(val.question);
                  if (flexWidth < 100) {
                    // 最小值100
                    flexWidth = 100;
                  }
                  widthList.push(flexWidth);
                } else {
                  flexWidth = this.$common.calculatedLength(item.answerMap[i]); // 按内容计算
                  if (flexWidth < 100) {
                    flexWidth = 100;
                  }
                  widthList.push(flexWidth);
                }
              } else {
                // 无答案
                flexWidth = this.$common.calculatedLength(val.question); // 按表头计算
                if (flexWidth < 80) {
                  flexWidth = 80;
                }
                widthList.push(flexWidth);
              }
            }
          } else {
            // 有表头无答案
            flexWidth = this.$common.calculatedLength(val.question); // 按表头计算
            if (flexWidth < 80) {
              flexWidth = 80;
            }
            widthList.push(flexWidth);
          }
        });
      } else {
        // 问卷无值
        flexWidth = this.$common.calculatedLength(val.question); // 按表头计算
        if (flexWidth < 80) {
          flexWidth = 80;
        }
        widthList.push(flexWidth);
      }
      widthList.sort((a, b) => b - a);
      return widthList[0] + 20 + "px";
    },
    // 用户名称获取焦点
    nameRemoteFocus() {
      this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
    },
    NameRemoteMethod(userName) {
      //搜索用户名称
      this.loadingName = true;
      setTimeout(() => {
        this.loadingName = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          userName: userName,
          enabled: true,
        };
        this.inquireLoginName(params);
      }, 200);
    },
    // 项目搜索获取焦点
    projectIdRemoteFocus() {
      let params = {};
      if (this.active === "1") {
        params = {
          pageSize: 20,
          pageNo: 1,
          loginName: this.condition.userName,
        };
      }
      if (this.active === "2") {
        params = {
          pageSize: 20,
          pageNo: 1,
          loginName: this.conditionWd.userName,
        };
      }
      this.inquireProject(params);
    },
    remoteMethod(projectName) {
      //搜索项目
      this.loading = true;
      let params = {};
      setTimeout(() => {
        this.loading = false;
        if (this.active === "1") {
          params = {
            pageSize: 20,
            pageNo: 1,
            loginName: this.condition.userName,
            projectName: projectName,
          };
        }
        if (this.active === "2") {
          params = {
            pageSize: 20,
            pageNo: 1,
            loginName: this.conditionWd.userName,
            projectName: projectName,
          };
        }
        this.inquireProject(params);
      }, 200);
    },
    // 任务搜索获取焦点
    taskRemoteFocus() {
      let params = {};
      if (this.active === "1") {
        params = {
          pageSize: 1000,
          pageNo: 1,
          loginName: this.condition.userName,
          projectId: this.condition.projectId,
        };
      }
      if (this.active === "2") {
        params = {
          pageSize: 1000,
          pageNo: 1,
          loginName: this.conditionWd.userName,
          projectId: this.conditionWd.projectId,
        };
      }
      this.inquireTask(params);
    },
    taskRemoteMethod(taskName) {
      //搜索任务
      this.loadingTask = true;
      setTimeout(() => {
        this.loadingTask = false;
        let params = {};
        if (this.active === "1") {
          params = {
            pageSize: 20,
            pageNo: 1,
            taskName: taskName,
            loginName: this.condition.userName,
            projectId: this.condition.projectId,
          };
        }
        if (this.active === "2") {
          params = {
            pageSize: 20,
            pageNo: 1,
            taskName: taskName,
            loginName: this.conditionWd.userName,
            projectId: this.conditionWd.projectId,
          };
        }
        this.inquireTask(params);
      }, 200);
    },
    currentPageWd_data(index) {
      //当前页
      this.inquireWd();
    },
    sizeWdChange(index) {
      //显示条数--分页
      this.pageSizeWd = index;
      this.inquireWd(1);
    },
    inquireWd(index) {
      //查询列表
      if (index) {
        this.currentPageWd = index;
      }
      let params = {
        pageSize: this.pageSizeWd,
        pageNo: this.currentPageWd,
        taskId: this.conditionWd.taskId,
        customerCodes: this.conditionWd.customerCode,
        clientNumber: this.conditionWd.clientNumber,
        teamId: this.conditionWd.teamId,
        callStartAtStart: this.conditionWd.callStartAtStart,
        callStartAtEnd: this.conditionWd.callStartAtEnd,
        flagModifiedAtStart: this.conditionWd.flagModifiedAtStart,
        flagModifiedAtEnd: this.conditionWd.flagModifiedAtEnd,
        projectId: this.conditionWd.projectId,
        loginName: this.conditionWd.userName,
        batchNumber: this.conditionWd.batchNumber,
        voiceFileNameFormat: this.voiceFileNameFormat,
        flagList: this.conditionWd.flagslist,
        checkResult: this.conditionWd.checkResult,
        diyQuestionFilterList: this.diyQuestionFilter2List,
        checker: this.loginName,
        recordingLabels: this.conditionWd.recordingLabelList.join(","),
      };
      if (this.conditionWd.callMillisStart) {
        params.callMillisStart = this.conditionWd.callMillisStart * 1000;
      }
      if (this.conditionWd.callMillisEnd) {
        params.callMillisEnd = this.conditionWd.callMillisEnd * 1000;
      }
      this.inquireAnswerList(params);
    },
    currentPage_data(index) {
      //当前页
      this.inquire();
    },
    sizeChange(index) {
      //显示条数--分页
      this.pageSize = index;
      this.inquire(1);
    },
    inquire(index) {
      //查询列表
      if (index) {
        this.currentPage = index;
      }
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        taskId: this.condition.taskId,
        customerCodes: this.condition.customerCode,
        clientNumber: this.condition.clientNumber,
        teamId: this.condition.teamId,
        callStartAtStart: this.condition.callStartAtStart,
        callStartAtEnd: this.condition.callStartAtEnd,
        flagModifiedAtStart: this.condition.flagModifiedAtStart,
        flagModifiedAtEnd: this.condition.flagModifiedAtEnd,
        projectId: this.condition.projectId,
        loginName: this.condition.userName,
        batchNumber: this.condition.batchNumber,
        voiceFileNameFormat: this.voiceFileNameFormat,
        flagList: this.condition.flagslist,
        diyQuestionFilterList: this.diyQuestionFilterList,
        wechatStatusList: this.condition.wechatStatusList,
        skipHasBoughtLesson: this.skipHasBoughtLesson,
        recordingLabels: this.condition.recordingLabelList.join(","),
      };
      if (this.condition.callMillisStart) {
        params.callMillisStart = this.condition.callMillisStart * 1000;
      }
      if (this.condition.callMillisEnd) {
        params.callMillisEnd = this.condition.callMillisEnd * 1000;
      }
      if (this.roleMenu !== "SUPER_MANAGER") {
        params.skipHasBoughtLesson = false;
      }
      this.inquireAnswerList(params);
    },
    inquireAnswerList(params) {
      //查询问卷列表
      if (this.registerName) {
        params.loginName = this.registerName;
      }
      let isHttp = false;
      if (params.taskId) {
        isHttp = true;
      }
      if (params.projectId) {
        isHttp = true;
      }
      if (params.batchNumber) {
        isHttp = true;
      }
      if (isHttp) {
        this.loadingTable = true;
        this.$service("/sip/questionnaire/list", params).then((res) => {
          setTimeout(() => {
            this.loadingTable = false;
          }, 200);
          if (res.result == 200) {
            let list = res.data.list;
            list.map((item) => {
              //数据转换
              let reasonList = [];
              if (item.failureReasonList) {
                item.failureReasonList.forEach((n) => {
                  reasonList.push(n.reason);
                });
              }
              item.failureReason = reasonList.join(" | ");
              switch (item.wechatStatus) {
                case "CREATING":
                  item.wechatStatusLabel = "请求发送中";
                  break;
                case "SUBMITTED":
                  item.wechatStatusLabel = "已提交好友申请";
                  break;
                case "NOT_FOUND":
                  item.wechatStatusLabel = "未查询到好友";
                  break;
                case "SUBMIT_FAILURE":
                  item.wechatStatusLabel = "好友申请异常";
                  break;
                case "EXPIRED":
                  item.wechatStatusLabel = "好友申请已过期";
                  break;
                case "RELOGIN":
                  item.wechatStatusLabel = "登录失效";
                  break;
                case "ADDEDFRIEND":
                  item.wechatStatusLabel = "已经是好友";
                  break;
                case "PASSED":
                  item.wechatStatusLabel = "通过好友申请";
                  break;
                case "CLIENT_OFFLINE":
                  item.wechatStatusLabel = "手机离线";
                  break;
                case "NOTLOGGEDIN":
                  item.wechatStatusLabel = "未登录";
                  break;
                case "NO_REQUEST":
                  item.wechatStatusLabel = "未加微信";
                  break;
                default:
                  item.wechatStatusLabel = "未知";
                  break;
              }
              item.customerAttribution = "";
              if (item.customerProvince && item.customerCity) {
                item.customerAttribution =
                  item.customerProvince + " " + item.customerCity;
              }
              item.option = {};
              item.arrAnswer = [];
              if (item.answerMap) {
                for (let key in item.answerMap) {
                  item.option["option_" + key] = item.answerMap[key];
                  if (this.headerId[key] == "CHECKBOX") {
                    item.option["option_" + key] =
                      item.answerMap[key].split(",");
                    this.header.map((h) => {
                      if (h.id == key) {
                        for (let i in h.optionList) {
                          for (let j in item.option["option_" + key]) {
                            if (
                              h.optionList[i].id ==
                              item.option["option_" + key][j]
                            ) {
                              item.option["option_" + key][j] =
                                h.optionList[i].option;
                            }
                          }
                        }
                      }
                    });
                    item.option["option_" + key] =
                      item.option["option_" + key].join("-");
                    // item.arrAnswer.push(item.option['option_'+key]);
                  }
                }
              }
              item.callEndAt = this.$common.transformTime(item.callEndAt);
              item.flagModifiedAt = this.$common.transformTime(
                item.flagModifiedAt
              );
              item.callAnsweredAt = this.$common.transformTime(
                item.callAnsweredAt
              );
              item.callReceivedAt = this.$common.transformTime(
                item.callReceivedAt
              );
              if (item.callSeconds == -1) {
                item.playVoiceDisabled = true;
                item.callSeconds = "-";
              } else {
                item.playVoiceDisabled = false;
                item.callSeconds = `${item.callSeconds} 秒`;
              }
              if (item.ringSeconds == -1) {
                item.ringSeconds = "-";
              } else {
                item.ringSeconds = `${item.ringSeconds} 秒`;
              }
              if (item.distributeSeconds == -1) {
                item.distributeSeconds = "-";
              } else {
                item.distributeSeconds = `${item.distributeSeconds} 秒`;
              }
              switch (item.callStatus) {
                case "UNALLOCATED_NUMBER": {
                  item.callStatus = "未分配号码";
                  break;
                }
                case "RINGING":
                  item.callStatus = "响铃中";
                  break;
                case "USER_BUSY":
                  item.callStatus = "用户拒接";
                  break;
                case "NO_ANSWER":
                  item.callStatus = "无人接听";
                  break;
                case "ORIGINATOR_CANCEL":
                  item.callStatus = "拨打取消";
                  break;
                case "TALKING":
                  item.callStatus = "通话中";
                  break;
                case "NORMAL_CLEARING":
                  item.callStatus = "拨打完成";
                  break;
                case "SYSTEM_SHUTDOWN":
                  item.callStatus = "系统挂断";
                  break;
                case "NO_USER_RESPONSE":
                  item.callStatus = "线路未响应";
                  break;
                case "NO_ROUTE_DESTINATION":
                  item.callStatus = "线路未响应";
                  break;
                case "SUBSCRIBER_ABSENT":
                  item.callStatus = "线路未响应";
                  break;
                case "MEDIA_TIMEOUT":
                  item.callStatus = "超时";
                  break;
                case "USER_NOT_REGISTERED":
                  item.callStatus = "坐席未注册";
                  break;
                case "WAITING_OUTCALL":
                  item.callStatus = "等待拨打";
                  break;
                case "CALLING":
                  item.callStatus = "拨打中";
                  break;
                case "TIMEOUT_CLEARING":
                  item.callStatus = "转接超时";
                  break;
                case "RECOVERY_ON_TIMER_EXPIRE":
                  item.callStatus = "拨打超限";
                  break;
                case "PARK":
                  item.callStatus = "等待转接";
                  break;
                case "NORMAL_TEMPORARY_FAILURE":
                  item.callStatus = "异常";
                  break;
                case "EXCEPTION":
                  item.callStatus = "异常";
                  break;
                case "OUT_OF_FEE":
                  item.callStatus = "欠费";
                  break;
              }
              switch (item.distributed) {
                case true:
                  item.distributed = "是";
                  break;
                case false:
                  item.distributed = "否";
                  break;
              }
              switch (item.connected) {
                case true:
                  item.connected = "是";
                  break;
                case false:
                  item.connected = "否";
                  break;
              }
            });
            if (this.active === "1") {
              this.header = res.data.header;
              this.total = res.data.count;
              let str = "";
              res.data.header.map((menu) => {
                this.headerId[menu.id] = menu.questionType;
              });
              this.$nextTick(() => {
                this.tableData = list;
                if (this.tableData && this.tableData.length > 0) {
                  this.downloadFormDisabled = false;
                  this.exportRecordDisabled = false;
                } else {
                  this.downloadFormDisabled = true;
                  this.exportRecordDisabled = true;
                }
              });
            }
            if (this.active === "2") {
              this.header = res.data.header;
              this.totalWd = res.data.count;
              let str = "";
              res.data.header.map((menu) => {
                this.headerId[menu.id] = menu.questionType;
              });
              this.$nextTick(() => {
                this.wdTableData = list;
                if (this.wdTableData && this.wdTableData.length > 0) {
                  this.downloadFormDisabled = false;
                  this.exportRecordDisabled = false;
                } else {
                  this.downloadFormDisabled = true;
                  this.exportRecordDisabled = true;
                }
              });
            }
          }
        });
      } else {
        this.$message({ message: "请选择项目或任务", type: "warning" });
      }
    },
    playRecord(val) {
      //点击质检录音
      this.playRecordShow = true;
      this.recordRemark = val.remark;
      this.callerUuid = val.callerUuid;
      this.checkClientNumber = val.clientNumber;
      this.customerCode = val.customerCode;
      this.clientNumber = val.clientNumber;
      this.projectId = val.projectId;
      this.punishValue = val.punishment;
      this.callId = val.id;
      this.server = val.server;
      this.userLoginName = val.userLoginName;
      if (!val.punishment) {
        this.punishValue = "退单";
      }
      this.errorCause = [];
      if (val.failureReasonList) {
        val.failureReasonList.forEach((item) => {
          this.errorCause.push(item.id);
        });
      }
      switch (val.checkResult) {
        case "NO_CHECK":
          this.recordRadio = "";
          break;
        case "PASSED":
          this.recordRadio = true;
          break;
        case "REJECTED":
          this.recordRadio = false;
          break;
      }
      this.$http(
        "/sip/questionnaire/getQuestionnaireStandard",
        null,
        "get",
        null,
        { projectId: val.projectId }
      ).then((res) => {
        if (res.result === 200) {
          if (res.data) {
            this.checkStandard = res.data.content;
          }
        }
      });
      this.$http(
        "/sip/questionnaire/getQuestionnaireFailureReason",
        null,
        "get",
        null,
        { projectId: val.projectId }
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            item.label = item.reason;
            if (item.reason.length > 20) {
              item.label = item.reason.substring(0, 20) + "...";
            }
          });
          this.errorCauseList = list;
          this.errorCauseShowList = [];
          this.errorCause.forEach((menu) => {
            this.errorCauseList.forEach((item) => {
              if (menu === item.id) {
                this.errorCauseShowList.push(item);
              }
            });
          });
        }
      });
      this.getFailureAnalysis();
      this.currentSrc = `https://${val.server}${this.$constants.basePath}/sip/playVoice?callerUuid=${val.callerUuid}&loginName=${val.userLoginName}`;
      let lastRunTime = Date.now(),
        currentTime = Date.now(),
        protectTime = 100;
      if (currentTime - lastRunTime < protectTime) {
        return; //两次执行太过频繁，直接退出
      }
      if (this.playFlag == false) {
        this.$refs.audio.play();
        this.playFlag = true;
      }
    },
    // 点击确定质检录音
    recordQuality_submit() {
      let data = {
        callerUuid: this.callerUuid,
        passed: this.recordRadio,
        remark: this.recordRemark,
        clientNumber: this.clientNumber,
        projectId: this.projectId,
      };
      data.reasonIdList = this.errorCause;
      data.punishment = this.punishValue;
      // // 只有在质检不通过时提交失败原因
      this.$service("/sip/questionnaire/checkRecord", data).then((res) => {
        if (res.result === 200) {
          this.playRecordShow = false;
          this.$message.success("操作成功");
          if (this.active === "1") {
            this.inquire();
          }
          if (this.active === "2") {
            this.inquireWd();
          }
        }
      });
    },
    downloadVoice(val) {
      //下载录音
      let params = {
        callerUuid: val.callerUuid,
        loginName: val.userLoginName,
        voiceFileNameFormat: "format1",
      };
      let url = "";
      for (let i in params) {
        if (params[i]) {
          url += i + "=" + params[i] + "&";
        }
      }
      let href =
        "https://" +
        val.server +
        this.$constants.basePath +
        "/sip/downloadVoice?" +
        url;
      window.location.href = href;
    },
    closeDialog() {
      //播放录音关闭
      this.$refs.audio.pause();
      this.playFlag = false;
      this.recordRadio = "";
      this.recordRemark = "";
      this.callerUuid = "";
      this.errorCauseList = [];
      this.errorCauseShowList = [];
      this.errorCause = [];
      this.checkStandard = "";
      this.checkClientNumber = "";
      this.customerCode = "";
      this.clientNumber = "";
      this.projectId = "";
      this.passRate = "";
      this.checkCount = "";
      this.reasonSummaryInfo = "";
      this.reasonSummaryInfoLabel = "";
      this.punishValue = "退单";
      this.punishSelect = "退单";
    },
    inquireLoginName(params) {
      //查询用户名称
      this.userNameList = [];
      this.$http("/user/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newuserNameList = res.data.list;
          newuserNameList.map((item) => {
            item.label = item.userName;
            this.userNameList.push(item);
          });
          if (this.isTeam) {
            // 是否是超级管理员
            let data = {
              pageNo: 1,
              pageSize: 100,
              loginName: this.loginName,
            };
            this.getTeamList(data);
          }
        }
      });
    },
    loginNameChange(userName) {
      //改变用户名称
      if (this.active === "1") {
        this.condition.projectId = "";
        this.condition.taskId = "";
        this.condition.teamId = "";
        this.conditionWd.userName = userName;
        this.conditionWd.projectId = "";
        this.conditionWd.taskId = "";
        this.conditionWd.teamId = "";
      }
      if (this.active === "2") {
        this.conditionWd.projectId = "";
        this.conditionWd.taskId = "";
        this.conditionWd.teamId = "";
      }
      if (userName) {
        let data = {
          pageNo: 1,
          pageSize: 100,
          loginName: userName,
        };
        this.getTeamList(data);
      }
    },
    inquireProject(params) {
      //查询项目名称
      this.projectNameList = [];
      this.$http("/sip/project/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newProjectNameList = res.data.list;
          newProjectNameList.map((item) => {
            this.projectNameList.push(item);
          });
        }
      });
    },
    projectIdChange(projectId) {
      //项目名称改变
      this.projectNameList.map((item) => {
        if (item.id == projectId) {
          // this.registerName = item.userLoginName
        }
      });
      if (this.active === "1") {
        this.condition.taskId = "";
      }
      if (this.active === "2") {
        this.conditionWd.taskId = "";
      }
      // let params = {
      //   pageSize:1000,
      //   pageNo:1,
      //   // loginName:this.registerName,
      //   projectId:this.condition.projectId
      // }
      // this.inquireTask(params);
      if (projectId) {
        this.projectQuestionWithCache(projectId);
      } else {
        this.options = {};
        this.optionCheckList = {};
      }
    },
    projectId2Change(projectId) {
      //项目名称改变
      this.projectNameList.map((item) => {
        if (item.id == projectId) {
          // this.registerName = item.userLoginName
        }
      });
      if (this.active === "1") {
        this.condition.taskId = "";
      }
      if (this.active === "2") {
        this.conditionWd.taskId = "";
      }
      // let params = {
      //   pageSize:1000,
      //   pageNo:1,
      //   // loginName:this.registerName,
      //   projectId:this.condition.projectId
      // }
      // this.inquireTask(params);
      if (projectId) {
        this.projectQuestionWithCache2(projectId);
      } else {
        this.options2 = {};
        this.optionCheck2List = {};
      }
    },
    taskIdChange(taskId) {
      //任务名称改变
      this.taskNameList.map((item) => {
        if (item.id == taskId) {
          // this.registerName = item.userLoginName
        }
      });
    },
    inquireTask(params) {
      //查询任务名称
      this.taskNameList = [];
      this.$http("/sip/task/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newTaskNameList = res.data.list;
          newTaskNameList.map((item) => {
            this.taskNameList.push(item);
          });
        }
      });
    },
    // 点击导出我的质检表
    exportWdCheck() {
      let params = {
        pageNo: 1,
        pageSize: 10000,
        taskId: this.conditionWd.taskId,
        customerCodes: this.conditionWd.customerCode,
        clientNumber: this.conditionWd.clientNumber,
        teamId: this.conditionWd.teamId,
        callStartAtStart: this.conditionWd.callStartAtStart,
        callStartAtEnd: this.conditionWd.callStartAtEnd,
        flagModifiedAtStart: this.conditionWd.flagModifiedAtStart,
        flagModifiedAtEnd: this.conditionWd.flagModifiedAtEnd,
        projectId: this.conditionWd.projectId,
        loginName: this.conditionWd.userName,
        batchNumber: this.conditionWd.batchNumber,
        checkResult: this.conditionWd.checkResult,
        voiceFileNameFormat: this.voiceFileNameFormat,
        flagList: this.conditionWd.flagslist,
        checker: this.loginName,
      };
      if (this.conditionWd.callMillisStart) {
        params.callMillisStart = this.conditionWd.callMillisStart * 1000;
      }
      if (this.conditionWd.callMillisEnd) {
        params.callMillisEnd = this.conditionWd.callMillisEnd * 1000;
      }
      let isHttp = false;
      if (params.taskId) {
        isHttp = true;
      }
      if (params.projectId) {
        isHttp = true;
      }
      if (params.batchNumber) {
        isHttp = true;
      }
      if (!isHttp) {
        this.$message.warning("请选择项目或任务");
        return;
      }
      let url = "";
      for (let i in params) {
        if (params[i]) {
          url += i + "=" + params[i] + "&";
        }
      }
      let href = this.$constants.baseURL + "/sip/questionnaire/export?" + url;
      this.downloadFormDisabled = true;
      this.$common.promiseDownload(href).then((data) => {
        setTimeout(() => {
          this.downloadFormDisabled = data;
        }, 4000);
      });
      window.location.href = href;
    },
    // 点击导出质检表
    exportCheck() {
      let params = {
        pageNo: 1,
        pageSize: 10000,
        taskId: this.condition.taskId,
        customerCodes: this.condition.customerCode,
        clientNumber: this.condition.clientNumber,
        teamId: this.condition.teamId,
        callStartAtStart: this.condition.callStartAtStart,
        callStartAtEnd: this.condition.callStartAtEnd,
        flagModifiedAtStart: this.condition.flagModifiedAtStart,
        flagModifiedAtEnd: this.condition.flagModifiedAtEnd,
        projectId: this.condition.projectId,
        loginName: this.condition.userName,
        batchNumber: this.condition.batchNumber,
        checkResult: this.condition.checkResult,
        voiceFileNameFormat: this.voiceFileNameFormat,
        flagList: this.condition.flagslist,
        wechatStatusList: this.condition.wechatStatusList,
        skipHasBoughtLesson: this.skipHasBoughtLesson,
        recordingLabels: this.condition.recordingLabelList.join(","),
      };
      if (this.condition.callMillisStart) {
        params.callMillisStart = this.condition.callMillisStart * 1000;
      }
      if (this.condition.callMillisEnd) {
        params.callMillisEnd = this.condition.callMillisEnd * 1000;
      }
      let isHttp = false;
      if (params.taskId) {
        isHttp = true;
      }
      if (params.projectId) {
        isHttp = true;
      }
      if (params.batchNumber) {
        isHttp = true;
      }
      if (!isHttp) {
        this.$message.warning("请选择项目或任务");
        return;
      }
      let url = "";
      for (let i in params) {
        if (params[i]) {
          url += i + "=" + params[i] + "&";
        }
      }
      let href = this.$constants.baseURL + "/sip/questionnaire/export?" + url;
      this.downloadFormDisabled = true;
      this.$common.promiseDownload(href).then((data) => {
        setTimeout(() => {
          this.downloadFormDisabled = data;
        }, 4000);
      });
      window.location.href = href;
    },
    downloadForm() {
      //下载表单
      let params = {};
      if (this.active === "1") {
        params = {
          taskId: this.condition.taskId,
          customerCodes: this.condition.customerCode,
          projectId: this.condition.projectId,
          teamId: this.condition.teamId,
          clientNumber: this.condition.clientNumber,
          callStartAtStart: this.condition.callStartAtStart,
          callStartAtEnd: this.condition.callStartAtEnd,
          flagModifiedAtStart: this.condition.flagModifiedAtStart,
          flagModifiedAtEnd: this.condition.flagModifiedAtEnd,
          loginName: this.condition.userName,
          batchNumber: this.condition.batchNumber,
          flagList: this.condition.flagslist,
          voiceFileNameFormat: this.voiceFileNameFormat,
          category: "ANSWER_EXCEL_LIST",
          diyQuestionFilterList: this.diyQuestionFilterList,
        };
        if (this.condition.callMillisStart) {
          params.callMillisStart = this.condition.callMillisStart * 1000;
        }
        if (this.condition.callMillisEnd) {
          params.callMillisEnd = this.condition.callMillisEnd * 1000;
        }
      }
      if (this.active === "2") {
        params = {
          taskId: this.conditionWd.taskId,
          customerCodes: this.conditionWd.customerCode,
          projectId: this.conditionWd.projectId,
          teamId: this.conditionWd.teamId,
          clientNumber: this.conditionWd.clientNumber,
          callStartAtStart: this.conditionWd.callStartAtStart,
          callStartAtEnd: this.conditionWd.callStartAtEnd,
          flagModifiedAtStart: this.conditionWd.flagModifiedAtStart,
          flagModifiedAtEnd: this.conditionWd.flagModifiedAtEnd,
          loginName: this.conditionWd.userName,
          batchNumber: this.conditionWd.batchNumber,
          flagList: this.conditionWd.flagslist,
          checkResult: this.conditionWd.checkResult,
          voiceFileNameFormat: this.voiceFileNameFormat,
          category: "ANSWER_EXCEL_LIST",
          diyQuestionFilterList: this.diyQuestionFilterList,
        };
        if (this.conditionWd.callMillisStart) {
          params.callMillisStart = this.conditionWd.callMillisStart * 1000;
        }
        if (this.conditionWd.callMillisEnd) {
          params.callMillisEnd = this.conditionWd.callMillisEnd * 1000;
        }
      }
      if (this.registerName) {
        params.loginName = this.registerName;
      }
      let isHttp = false;
      if (params.taskId) {
        isHttp = true;
      }
      if (params.projectId) {
        isHttp = true;
      }
      if (params.batchNumber) {
        isHttp = true;
      }
      if (isHttp) {
        this.downloadFormDisabled = true;
        this.$service("/sip/downloadFile/addDownloadTask", params).then(
          (res) => {
            this.downloadFormDisabled = false;
            if (res.result == 200) {
              this.downloadTips = true;
              this.downloadId = res.data.id;
              this.downloadStatus();
            }
          }
        );
        // let url = '';
        // for(let i in params){
        //   if(params[i]){
        //     url+=(i+'='+params[i]+'&')
        //   }
        // }
        // let href = this.$constants.baseURL+"/sip/call/exportAnswerList?"+url;
        // this.downloadFormDisabled = true;
        // this.$common.promiseDownload(href).then(data=>{
        //   setTimeout(() => {
        //     this.downloadFormDisabled = data;
        //   }, 4000);
        // })
        // window.location.href = href;
      } else {
        this.$message({ message: "请选择项目或任务", type: "warning" });
      }
    },
    exportRecording() {
      //导出录音
      let params = {};
      if (this.active === "1") {
        params = {
          pageSize: 10,
          pageNo: this.currentPage,
          taskId: this.condition.taskId,
          customerCodes: this.condition.customerCode,
          clientNumber: this.condition.clientNumber,
          callStartAtStart: this.condition.callStartAtStart,
          callStartAtEnd: this.condition.callStartAtEnd,
          flagModifiedAtStart: this.condition.flagModifiedAtStart,
          flagModifiedAtEnd: this.condition.flagModifiedAtEnd,
          projectId: this.condition.projectId,
          teamId: this.condition.teamId,
          loginName: this.condition.userName,
          batchNumber: this.condition.batchNumber,
          voiceFileNameFormat: this.voiceFileNameFormat,
          flagList: this.condition.flagslist,
          category: "ANSWER_RECORD_LIST",
          diyQuestionFilterList: this.diyQuestionFilterList,
        };
        if (this.condition.callMillisStart) {
          params.callMillisStart = this.condition.callMillisStart * 1000;
        }
        if (this.condition.callMillisEnd) {
          params.callMillisEnd = this.condition.callMillisEnd * 1000;
        }
      }
      if (this.active === "2") {
        params = {
          pageSize: 10,
          pageNo: this.currentPage,
          taskId: this.conditionWd.taskId,
          customerCodes: this.conditionWd.customerCode,
          clientNumber: this.conditionWd.clientNumber,
          callStartAtStart: this.conditionWd.callStartAtStart,
          callStartAtEnd: this.conditionWd.callStartAtEnd,
          flagModifiedAtStart: this.conditionWd.flagModifiedAtStart,
          flagModifiedAtEnd: this.conditionWd.flagModifiedAtEnd,
          projectId: this.conditionWd.projectId,
          teamId: this.conditionWd.teamId,
          loginName: this.conditionWd.userName,
          batchNumber: this.conditionWd.batchNumber,
          checkResult: this.conditionWd.checkResult,
          voiceFileNameFormat: this.voiceFileNameFormat,
          flagList: this.conditionWd.flagslist,
          category: "ANSWER_RECORD_LIST",
          diyQuestionFilterList: this.diyQuestionFilterList,
        };
        if (this.conditionWd.callMillisStart) {
          params.callMillisStart = this.conditionWd.callMillisStart * 1000;
        }
        if (this.conditionWd.callMillisEnd) {
          params.callMillisEnd = this.conditionWd.callMillisEnd * 1000;
        }
      }
      if (this.registerName) {
        params.loginName = this.registerName;
      }
      let isHttp = false;
      if (params.taskId) {
        isHttp = true;
      }
      if (params.projectId) {
        isHttp = true;
      }
      if (params.batchNumber) {
        isHttp = true;
      }
      if (isHttp) {
        this.exportRecordDisabled = true;
        this.$service("/sip/downloadFile/addDownloadTask", params).then(
          (res) => {
            this.exportRecordDisabled = false;
            if (res.result == 200) {
              this.downloadTips = true;
              this.downloadId = res.data.id;
              this.downloadStatus();
            }
          }
        );
        // let url = '';
        // for(let i in params){
        //   if(params[i]){
        //     url+=(i+'='+params[i]+'&')
        //   }
        // }
        // let href = this.$constants.baseURL+"/sip/recordingFiles/download?"+url;
        // this.exportRecordDisabled = true;
        // this.$common.promiseDownload(href).then(data=>{
        //   setTimeout(() => {
        //     this.exportRecordDisabled = data;
        //   }, 4000);
        // })
        // window.location.href = href;
      } else {
        this.$message({ message: "请选择项目或任务", type: "warning" });
      }
    },
    //选择录音格式
    voiceFileNameChange() {
      this.voiceFileNameLabel = "录音格式";
      if (this.voiceFileNameFormat) {
        switch (this.voiceFileNameFormat) {
          case "format1":
            this.voiceFileNameLabel = "日期_坐席编号_客户编号";
            break;
          case "format2":
            this.voiceFileNameLabel = "日期_坐席编号_手机号码";
            break;
          case "format3":
            this.voiceFileNameLabel = "日期^客户编号";
            break;
          case "format4":
            this.voiceFileNameLabel = "日期_手机号";
            break;
          case "format5":
            this.voiceFileNameLabel = "900";
            break;
          case "format6":
            this.voiceFileNameLabel = "手机号";
            break;
        }
      }
      this.recordShow = false;
    },
    //点击录音格式
    exportRecord() {
      this.recordShow = true;
    },
    //关闭录音格式
    closeRecord() {
      // this.voiceFileNameFormat = ''
    },
    flagPitchOn(val) {
      //flags改变
      let flagList = val;
      // console.log(val)
      this.condition.flagList = flagList;
    },
  },
};
</script>

<style scoped="scoped">
.records_style >>> .el-dialog__body::after {
  display: block;
  content: "";
  clear: both;
}
.error_cause_style {
  display: flex;
}
.item_zlfx {
  display: inline-block;
}
.play_record_i {
  display: flex;
  justify-content: space-between;
}
.play_record_i .play_record_item .play_record_zhijian {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}
.play_record_item .el-form-item {
  margin-bottom: 10px;
}
.answer-class >>> .el-tooltip {
  width: 100% !important;
}
.callerTask {
  position: absolute;
  top: 60px;
  left: 650px;
  width: 330px;
}
.el-checkbox-group {
  width: 500px;
  display: inline-block;
}
.iconColor {
  color: #1fbd1f;
}
.call {
  position: relative;
  margin-bottom: 50px;
}
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.nav {
  position: relative;
}
.callTime {
  position: absolute;
  width: 510px;
  top: 0px;
  left: 650px;
}
.operate {
  clear: both;
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
.callerNumberWidth {
  width: 564px !important;
}
.callerNumber,
.calleeNumber,
.callSecondsTime,
.callStatus,
.projectStatus {
  width: 330px;
  margin-bottom: 20px;
}
.nav .el-input,
.nav .el-select {
  width: 200px;
}
.callerNumber,
.calleeNumber {
  text-align: left;
  float: left;
}
.callSecondsTime .call-startTime {
  width: 200px !important;
  margin-right: 6px;
}
.callSecondsTime .call-endTime {
  width: 200px !important;
  margin-left: 7px;
}
.customDialog >>> .el-dialog {
  border-radius: 50px;
  background: transparent;
}
.customDialog >>> .el-dialog__header {
  padding: 0px;
}
.customDialog >>> .el-dialog__body {
  padding: 0px;
  font-size: 0px;
}
.question_input {
  width: 217px;
}
.selfFilterClass >>> .el-dialog__body {
  padding-bottom: 0;
}
.historical >>> .el-dialog__body {
  position: relative;
}
.changeClass {
  display: inline-block;
  width: 48%;
}
.tableChange {
  float: left;
  margin-bottom: 20px;
}
.formChange {
  float: right;
}
.el-radio-group >>> .el-radio__label {
  padding-left: 5px;
}
.dialog-footer {
  clear: both;
}

.audio_div {
  width: 100%;
  height: 45px;
  margin-bottom: 10px;
}
.audio_div .audio_class {
  width: 100%;
  height: 40px;
}
.dialogue_style {
  width: 100%;
  height: calc(100vh - 100px);
  overflow: auto;
  background: #f2f6fc;
}
.dialogue_style_li {
  margin: 15px 0;
  padding: 0 10px;
}
.drawer_time_line {
  width: 530px;
  padding: 0 20px 20px;
  box-sizing: content-box;
}
.drawFlex {
  display: flex;
  flex-wrap: nowrap;
}
.drawFlex .form {
  width: 410px;
  padding-left: 20px;
  position: relative;
  height: calc(100vh - 78px);
}
.drawFlex .form .el-form {
  height: calc(100% - 125px);
  overflow: auto;
}

.drawFlex .form .el-form .el-form-item:last-of-type {
  margin-bottom: 90px !important;
}

.drawFlex .form .el-form::-webkit-scrollbar {
  display: none;
}

.drawFlex .form .el-form .el-checkbox-group {
  width: 100% !important;
}

.dialogue_style_li .dialogue_div_service {
  display: flex;
  justify-content: flex-start;
}
.dialogue_style_li .dialogue_style_service {
  text-align: left;
}
.dialogue_style_li .dialogue_style_service .dialogue_style_service_avatar {
  margin-right: 10px;
}
.dialogue_style_li .dialogue_style_service .dialogue_style_service_sign {
  font-size: 12px;
  color: #fa541c;
  line-height: 22px;
  border: 1px solid #fa541c;
  background-color: #ffffff;
  border-radius: 3px;
  margin-top: 8px;
  margin-left: 38px;
  padding: 0 8px;
  display: inline-block;
}
.dialogue_style_li .dialogue_style_service .dialogue_style_service_content {
  max-width: 350px;
  padding: 8px 8px;
  background: #ffffff;
  border-radius: 5px;
  color: #303133;
  display: inline-block;
}
.dialogue_style_li .dialogue_div_customer {
  display: flex;
  justify-content: flex-end;
}
.dialogue_style_li .dialogue_style_customer {
  text-align: left;
}
.dialogue_style_li .dialogue_style_customer .dialogue_style_service_sign {
  font-size: 12px;
  color: #fa541c;
  line-height: 22px;
  border: 1px solid #fa541c;
  background-color: #ffffff;
  border-radius: 3px;
  margin-top: 8px;
  margin-right: 38px;
  padding: 0 8px;
  display: inline-block;
}
.dialogue_style_li .dialogue_style_customer .dialogue_style_customer_avatar {
  margin-left: 10px;
}
.dialogue_style_li .dialogue_style_customer .dialogue_style_customer_content {
  max-width: 350px;
  padding: 8px 8px;
  background: #90ee62;
  border-radius: 5px;
  color: #303133;
  display: inline-block;
}
.dialogue_style_li .dialogue_style_tips {
  display: flex;
  justify-content: center;
}
.dialogue_style_li .dialogue_style_tips .dialogue_style_tips_text {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.1);
  color: #ffffff;
  border-radius: 4px;
  padding: 0 4px;
  line-height: 22px;
  font-size: 12px;
}
.copy {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 55px;
  height: 40px;
}
.icon {
  font-size: 20px;
}
.seeCustomerCode-class >>> .el-dialog__title {
  font-size: 16px;
}
.seeCustomerCode-class >>> .el-dialog__body {
  padding: 10px 20px 5px 20px;
}
.seeCustomerCode-class >>> .el-dialog__body .el-form-item__label {
  font-size: 17px;
}
.seeCustomerCode-class >>> .el-dialog__body .el-form-item__content {
  font-size: 17px;
}
.customerCode_class {
  display: flex;
  justify-content: space-between;
}
.dropbox-ts {
  font-size: 12px;
  color: red;
  display: block;
  line-height: 16px;
}
.passDrawer >>> .el-drawer__body {
  overflow: hidden;
}
</style>
